import React, { useContext, useEffect, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import { useNavigate } from 'react-router-dom'
import Invoice_rejected_table from '../Invoice_Rejected/Invoice_rejected_table'

const All_invoice_view = ({ ViewTypeDown, MakerTypeDown }) => {
    const { InvoiceRec, proformaAccess, invoiceAccess, RaiseCreditNote, isAdmin, IsProfoma, setRegFlag, SearchContractNumberCMS, SalesAmountRegUnReg, setsalesAmountRegUnReg, GstFlag, setGstFlag, ApproveInvoice, ContractCustomer, RejectInvoice, SetShow, DoInvoiceCalculation, DeleteInvoice, handleInvoiceDownload, InvoiceAmt, InvoiceTypeObj, handleViewInvoice, HSNCODE, CustomerType, EditFlag, setEditFlag, OnInvoiceSubmit, ReverseCharge, setReverseCharge, handleCustomerPanSearch, setInvoiceAmt, PanValue,
        setPanValue, CalculationResponse, setCallStatus, CalculateType, setCalculateType, GetUnits, SelectedCustomer, OnInvoiceUpdateSubmit,
        CurrentUnitIdentifier, setCurrentUnitIdentifier, createPageSupplierData, GetItems, ItemData, CurrentItemIdentifier, SubmitInvoice,
        setCurrentItemidentifier, AllInvoiceInvoiceTypeDrop, setAllInvoiceInvoiceTypeDrop, GetCustomer, InvoiceReqObj, cmsNumber, setcmsNumber, handleNON_CMSCustomerPaginationNumber, nonCMS, setnoncmssearch, setnonCMS,
        setInvoiceReqObj, AllInvoiceErr, setcustomviewshow, setBillIdentifier, setShipIdentifier, handlsingleemail, MarginAmt, setMarginAmt, CMSCustomerSearch, setCMSCustomerSearch } = useContext(E_invoice_context)

        console.log(InvoiceRec)

        console.log(createPageSupplierData)

    const GetNonCmsRecords = () => {
        GetCustomer(1, "");
        SetShow(3)
    }
    const GetCmsRecords = (reg) => {
        setRegFlag(reg)
        GetCustomer(2, "");
        SetShow(6)
    }

    var MakerFlag = false;
    var CheckerFlag = false;
    if (isAdmin) {
        MakerFlag = true
        CheckerFlag = true
    }
    else {
        if (IsProfoma) {
            if (proformaAccess && proformaAccess.length === 0) {
                MakerFlag = true
                CheckerFlag = true
            }
            else {
                // console.log('pp', proformaAccess && proformaAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === InvoiceTypeObj.invoiceTypeConfigurationAutoId)))
                if (proformaAccess) {
                    var proAccess = proformaAccess && proformaAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === InvoiceTypeObj.invoiceTypeConfigurationAutoId))
                    MakerFlag = proAccess && proAccess[0] && proAccess[0].makerFlag;
                    CheckerFlag = proAccess && proAccess[0] && proAccess[0].checkerFlag;
                }
            }
        }
        else {
            if (invoiceAccess && invoiceAccess.length === 0) {
                MakerFlag = true
                CheckerFlag = true
            }
            else {
                // console.log("invoiceAccess", invoiceAccess, InvoiceTypeObj)
                // console.log('invac', invoiceAccess && invoiceAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === InvoiceTypeObj.invoiceTypeConfigurationAutoId)))
                if (invoiceAccess) {
                    var Invaccess = invoiceAccess && invoiceAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === InvoiceTypeObj.invoiceTypeConfigurationAutoId));
                    MakerFlag = Invaccess && Invaccess[0] && Invaccess[0].makerFlag;
                    CheckerFlag = Invaccess && Invaccess[0] && Invaccess[0].checkerFlag;
                }
            }
        }
    }



    const handleCalculationCall = () => {
        // if (Number(InvoiceAmt) === 0) {
        //     setInvoiceAmt(SalesAmountRegUnReg)
        // }
        setCallStatus((Math.random() * 1000) + 1)
    }

    const handleUpdateAMt = (e) => {
        setsalesAmountRegUnReg(e.target.value);
        setInvoiceAmt(e.target.value)
        if (GstFlag !== 1) {
            setMarginAmt(0)
        }
    }

    const handleCalculateType = (e) => {
        setCalculateType(e.target.value);
    }

    const ApproveModal = () => {
        document.getElementById('invoice_Approval').click();
    }

    const rejectModal = () => {
        document.getElementById('model_triger_three').click();
    }

    const handleApproveInvoice = (identifier) => {
        ApproveInvoice(identifier, IsProfoma);
        SetShow(1)
        document.getElementById('Approval2_success_model').click();
    }

    const handleRejectInvoice = (identifier) => {
        const rejectionReason = document.getElementById('rejectionReason').value;

        RejectInvoice(identifier, rejectionReason);
        SetShow(1)
        document.getElementById('Reject_success_model').click();
    }

    const handleCreditNote = (identifier) => {
        RaiseCreditNote(identifier, 1);
        SetShow(1)
    }
    const DeleteModal = () => {
        document.getElementById('Invoice_delete_model').click();
    }

    const handleDeleteInvoice = (identifier) => {
        DeleteInvoice(identifier);
        SetShow(1)
        document.getElementById('Invoice_success_delete').click();
    }

    const cancelReset = () => {
        handleViewInvoice(InvoiceRec.invoiceIdentifier)
        setEditFlag("0")
        setCallStatus(0)
    }
    const creditinvoice = () => {
        document.getElementById('Invoice_credit_successs').click();
    }

    // console.log(MakerTypeDown)


    const maker = MakerTypeDown.filter((itm) => itm.activeFlag !== true)
    //  console.log(maker)

    const noncmsclick = () => {
        GetNonCmsRecords();
        setBillIdentifier("");
        setShipIdentifier("");
        setnonCMS({ ...nonCMS, billingStatesCode: nonCMS.billingStatesCode = "", shippingStatesCode: nonCMS.shippingStatesCode = "", search: nonCMS.search = "", regUnRegFlag: nonCMS.regUnRegFlag = "0" })
        setnoncmssearch('')
        handleNON_CMSCustomerPaginationNumber(1)
    }
    return (
        <div>


            <input type="text" id='Invoice_credit_successs' hidden data-bs-toggle="modal" data-bs-target="#invoice_credit" />
            <div className="modal fade mdl_e_fade" id="invoice_credit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Do you want to raise a Credit Note for this invoice?
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" onClick={() => { handleCreditNote(InvoiceRec.invoiceIdentifier) }} className="btn mdl_btn_dwn">Yes</button>
                            <button type="button" className=" btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='Invoice_delete_model' hidden data-bs-toggle="modal" data-bs-target="#invoice_delete" />
            <div className="modal fade mdl_e_fade" id="invoice_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure want to delete this invoice?
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" onClick={() => handleDeleteInvoice(InvoiceRec.invoiceIdentifier)} className="btn mdl_btn_dwn">Yes</button>
                            <button type="button" className=" btn mdl_btn_cancel" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>




            <input type="text" id='Invoice_success_model' hidden data-bs-toggle="modal" data-bs-target="#invoice_Success_mdl" />
            <div className="modal fade mdl_e_fade" id="invoice_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Invoice has been created successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='Invoice_success_update' hidden data-bs-toggle="modal" data-bs-target="#Invoice_success_update_model" />
            <div className="modal fade mdl_e_fade" id="Invoice_success_update_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Invoice has been Update successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='Invoice_success_delete' hidden data-bs-toggle="modal" data-bs-target="#Invoice_success_delete_model" />
            <div className="modal fade mdl_e_fade" id="Invoice_success_delete_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Invoice has been deleted successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='model_triger_three' hidden data-bs-toggle="modal" data-bs-target="#model_two_ins" />

            <div className="modal fade mdl_e_fade" id="model_two_ins" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            <p style={{ color: '#1a1a1a', marginBottom: '5px', fontSize: '13px' }}>Reason For Rejection</p>
                            <textarea className="input_txt_are" name="itemDescription" placeholder="" id="rejectionReason" rows="2"></textarea>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={() => handleRejectInvoice(InvoiceRec.invoiceIdentifier)} type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className='top_hd_aci btn_1'>
                {InvoiceRec.invoiceStatus === 0 || InvoiceRec.invoiceStatus === 2 ?
                    (EditFlag !== "1" ?
                        (MakerFlag && <>
                            <button className='mx-2 grn_btn' onClick={() => setEditFlag("1")}>Edit</button>
                            <button className='diable_btn_edit' onClick={DeleteModal}>Delete</button>
                        </>)
                        : "") :
                    <>
                        <button className='mx-2 grn_btn blue' onClick={() => handleInvoiceDownload(InvoiceRec.invoiceIdentifier, 0)}>Print</button>
                        <button className='mx-2 grn_btn blue' onClick={() => handlsingleemail(InvoiceRec.invoiceIdentifier)}>Send Email</button>
                        <button className='mx-2 grn_btn blue' onClick={() => handleInvoiceDownload(InvoiceRec.invoiceIdentifier, 0)}>Download</button>
                        {(((IsProfoma === false && InvoiceRec.invoiceStatus === 1) && (InvoiceRec.invoiceNoteStatus === 0 || InvoiceRec.invoiceNoteStatus === 3)) && CheckerFlag) &&
                            <button className='mx-2 grn_btn blue' onClick={creditinvoice}>Credit Note</button>}
                    </>}
            </div>
            <div className='total_outfit_fr_tab'>
                <div className='top_hd_aci'>
                    <p><b>Invoice No: </b> <span>{InvoiceRec && InvoiceRec.invoiceStatus !== 1 ? "To be Generated" : InvoiceRec.invoiceSerialNumber}</span></p>
                    <p><b>Invoice Date: </b><span>{InvoiceRec && InvoiceRec.invoiceStatus !== 1 ? "To be Generated" : InvoiceRec.invoiceDate}</span></p>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span>Supplier Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <label className='label_w_s' htmlFor="a_c_i_sel">Invoice Type</label>

                                {EditFlag === "0" ?
                                    <>                                    <select disabled={EditFlag === "0"} style={{ width: '300px' }} value={AllInvoiceInvoiceTypeDrop} onChange={(e) => setAllInvoiceInvoiceTypeDrop(e.target.value)} className="select_inp_filed" aria-label="Default select example">
                                        <option selected value=''>Invoice Type</option>
                                        {
                                            ViewTypeDown && ViewTypeDown.map((itemdrop, uq) => (
                                                <option key={uq} value={itemdrop.invoiceTypeConfigurationIdentifier}>
                                                    {itemdrop.invoiceTypeName}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    </>
                                    : <>
                                        <select disabled={EditFlag === "0"} style={{ width: '300px' }} value={AllInvoiceInvoiceTypeDrop} onChange={(e) => { setAllInvoiceInvoiceTypeDrop(e.target.value); setCurrentItemidentifier('') }} className="select_inp_filed" aria-label="Default select example">
                                            <option selected value=''>Invoice Type</option>
                                            {
                                                MakerTypeDown && MakerTypeDown.map((itemdrop, uq) => (
                                                    <option key={uq} disabled={itemdrop.activeFlag === false} value={itemdrop.invoiceTypeConfigurationIdentifier}>
                                                        {itemdrop.invoiceTypeName}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        {
                                            AllInvoiceErr === false && AllInvoiceInvoiceTypeDrop === '' &&
                                            <p style={{ fontSize: '12px', color: 'red' }}>Please Provide valid Information</p>
                                        }
                                    </>


                                }
                                {
                                    InvoiceRec && InvoiceRec.invoiceStatus === 1 ? (
                                        <div className='supplier_details mt-3'>
                                            <h6>Supplier Details</h6>
                                            <div className='row'>
                                                <div className='col-lg-3 col-12'>
                                                    <label htmlFor="">Supplier Name</label>
                                                    <p>{InvoiceRec && InvoiceRec.supplierLegalName}</p>
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <label htmlFor="">Supplier Address</label>
                                                    <p>{InvoiceRec && `${InvoiceRec.supplierLocation}, ${InvoiceRec.supplierBuildingNumber},${InvoiceRec.supplierDistrict},${InvoiceRec.supplierPincode}`}</p>
                                                    {/* <label htmlFor="">--Supplier StateCode</label>
                                                    <p>{createPageSupplierData && createPageSupplierData.supplierStateCode}</p> */}
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <label htmlFor="">Supplier Pan Number</label>
                                                    <p>{InvoiceRec && InvoiceRec.supplierPan}</p>
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <label htmlFor="">Supplier GST</label>
                                                    <p>{InvoiceRec && InvoiceRec.supplierGstin}</p>
                                                </div>
                                            </div>
                                        </div>) : (createPageSupplierData && createPageSupplierData != '' && (
                                            <div className='supplier_details mt-3'>
                                                <h6>Supplier Details</h6>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label htmlFor="">Supplier Name</label>
                                                        <p>{createPageSupplierData && createPageSupplierData.supplierLegalName}</p>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label htmlFor="">Supplier Address</label>
                                                        <p>{createPageSupplierData && `${createPageSupplierData.supplierLocation}, ${createPageSupplierData.supplierBuildingNumber},${createPageSupplierData.supplierDistrict},${createPageSupplierData.supplierPincode}`}</p>
                                                        {/* <label htmlFor="">--Supplier StateCode</label>
                                                    <p>{createPageSupplierData && createPageSupplierData.supplierStateCode}</p> */}
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label htmlFor="">Supplier Pan Number</label>
                                                        <p>{createPageSupplierData && createPageSupplierData.supplierPANNumber}</p>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label htmlFor="">Supplier GST</label>
                                                        <p>{createPageSupplierData && createPageSupplierData.supplierGSTIN}</p>
                                                    </div>
                                                </div>
                                            </div>))
                                }
                            </div>
                        </div>
                    </div>

                    <input type="text" id='Email_Success_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_Success_mdl" />
                    <div className="modal fade mdl_e_fade" id="Email_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                    Mail sent successfully.
                                </div>
                                <div className="modal-footer">

                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <input type="text" id='Email_Fail_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_Success_mdl" />
                    <div className="modal fade mdl_e_fade" id="Email_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                    Something went wrong.
                                </div>
                                <div className="modal-footer">

                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <span>Customer Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <div className='row'>
                                    {EditFlag === "1" && <div className='col-lg-6 col-12 '>
                                        <label className='label_w_s' htmlFor="a_c_i_sel">Search Customer Details</label>
                                        <div className='c_d_sept'>
                                            <button onClick={noncmsclick} className='grn_btn'>Customer Master -Non CMS</button>
                                            <button onClick={() => { GetCmsRecords(false); setcustomviewshow(1); setCMSCustomerSearch({ ...CMSCustomerSearch, cms: "" }) }} className='grn_btn'>Customer Master -  CMS</button>
                                        </div>
                                    </div>}
                                    <div className='col-lg-6 col-12 serach_cus'>
                                        {EditFlag === "1" && <div >
                                            <label className='label_w_s' htmlFor="a_c_i_pan">PAN Number</label>
                                            <div className='pan_cd_sept'>
                                                <input id='a_c_i_pan' value={PanValue} className="search_inp_filed" onChange={(e) => setPanValue(e.target.value)} type="text" name="PanNumber" placeholder=""></input>
                                                <i onClick={handleCustomerPanSearch} className="fa-solid fa-magnifying-glass search_icon"></i>
                                            </div>
                                        </div>}
                                        {InvoiceRec && InvoiceRec.invoiceStatus === 1 ?
                                            <div className='c_t_n_sept'>
                                                <label className='label_w_s' htmlFor="a_c_i_pan">Customer Trade Name</label>
                                                <input id='a_c_i_pan' value={InvoiceRec && InvoiceRec.customerMasterTradeName} style={{ width: '100%' }} disabled className="search_inp_filed" type="text" name="" placeholder=""></input>
                                            </div> : <div className='c_t_n_sept'>
                                                <label className='label_w_s' htmlFor="a_c_i_pan">Customer Trade Name</label>
                                                <input id='a_c_i_pan' value={SelectedCustomer && (CustomerType === 1 ? SelectedCustomer.customerTradeName : SelectedCustomer.customerName)} style={{ width: '100%' }} disabled className="search_inp_filed" type="text" name="" placeholder=""></input>
                                            </div>
                                        }
                                    </div>

                                    {InvoiceRec && InvoiceRec.invoiceStatus === 1 ?
                                        (InvoiceRec.customerType === 1 ? (<div className='row m-0 mt-3 c_d_invoice'>
                                            <div className='col-lg-5 col-12'>
                                                <span>Bill To Address</span>
                                                <div className='row c_d_inoice_on'>
                                                    <div className='col-lg-5 col-12'>
                                                        <label htmlFor="">Customer Legal Name</label>
                                                        <p>{InvoiceRec && InvoiceRec.contractMasterLegalName}</p>

                                                        <label htmlFor="">Customer Phone</label>
                                                        <p>{InvoiceRec && InvoiceRec.customerPhone}</p>
                                                    </div>
                                                    <div className='col-lg-7 col-12'>
                                                        <label htmlFor="">Customer GST</label>
                                                        <p>{InvoiceRec && InvoiceRec.customerGst}</p>

                                                        <label htmlFor="">Customer Email</label>
                                                        <p>{InvoiceRec && InvoiceRec.customerEmail}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-5 col-12 ship_add_col'>
                                                <span>Ship To Address</span>
                                                <div className='row c_d_inoice_tw'>
                                                    <div className='col-lg-6 col-12'>
                                                        <label htmlFor="">GST</label>
                                                        <p>{InvoiceRec && InvoiceRec.shipGst}</p>

                                                        <label htmlFor="">Email</label>
                                                        <p>{InvoiceRec && InvoiceRec.shipEmail}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-12'>
                                                        <label htmlFor="">Phone</label>
                                                        <p>{InvoiceRec && InvoiceRec.shipPhone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                                <div>
                                                    <label htmlFor="">{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "B2C" : "B2B"}</label>
                                                    <p>{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "Unregistered" : "Registered"}</p>
                                                </div>
                                            </div>
                                        </div>) : (<div className='supplier_details pt-0 pb-0 mt-3'>
                                            <div className='row'>
                                                <div className='col-lg-5 col-12 suP_div'>
                                                    <div className='sup_2_div'>
                                                        <div>
                                                            <label htmlFor="">Address 1</label>
                                                            <p>{InvoiceRec && InvoiceRec.customerLocation}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Name</label>
                                                            <p>{InvoiceRec && InvoiceRec.contractMasterLegalName}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Phone</label>
                                                            <p>{InvoiceRec && InvoiceRec.shipPhone}</p>
                                                        </div>
                                                    </div>
                                                    <div className='sup_thr_div'>
                                                        <div>
                                                            <label htmlFor="">Pan Number</label>
                                                            <p>{InvoiceRec && InvoiceRec.customerPannumber}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Email</label>
                                                            <p>{InvoiceRec && InvoiceRec.shipEmail}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 col-12 suP_div bdr'>
                                                    <div className='sup_2_div'>
                                                        <div>
                                                            <label htmlFor="">Address 2</label>
                                                            <p>{InvoiceRec && InvoiceRec.shipLocation}</p>
                                                        </div>
                                                        <div style={{ visibility: 'hidden' }}>
                                                            <label htmlFor="">Purchase Tax TIN NO</label>
                                                            <p>{InvoiceRec && InvoiceRec.shipGst}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Email 2</label>
                                                            <p>{InvoiceRec && InvoiceRec.customerEmail}</p>
                                                        </div>
                                                    </div>
                                                    <div className='sup_thr_div'>
                                                        <div>
                                                            <label htmlFor="">Customer Cellular</label>
                                                            <p>{InvoiceRec && InvoiceRec.customerPhone}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-12 suP_div thr'>
                                                    <div>
                                                        <label htmlFor="">{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "B2C" : "B2B"}</label>
                                                        <p>{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "Unregistered" : "Registered"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)) :
                                        (SelectedCustomer && (CustomerType === 1 ? (<div className='row m-0 mt-3 c_d_invoice'>
                                            <div className='col-lg-5 col-12'>
                                                <span>Bill To Address</span>
                                                <div className='row c_d_inoice_on'>
                                                    <div className='col-lg-5 col-12'>
                                                        <label htmlFor="">Customer Legal Name</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.customerLegalName}</p>

                                                        <label htmlFor="">Customer Phone</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerPhone}</p>
                                                    </div>
                                                    <div className='col-lg-7 col-12'>
                                                        <label htmlFor="">Customer GST</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerGstin}</p>

                                                        <label htmlFor="">Customer Email</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerEmail}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-5 col-12 ship_add_col'>
                                                <span>Ship To Address</span>
                                                <div className='row c_d_inoice_tw'>
                                                    <div className='col-lg-6 col-12'>
                                                        <label htmlFor="">GST</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0].shipGstin}</p>

                                                        <label htmlFor="">Email</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0].shipEmail}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-12'>
                                                        <label htmlFor="">Phone</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0] &&
                                                            SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0].shipPhone}</p>
                                                        {/* <label htmlFor="">--Ship StateCode</label>
                                                    <p>{SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses &&
                                                        SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0] &&
                                                        SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0].shipStateCode}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                                <div>
                                                    <label htmlFor="">{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "B2C" : "B2B"}</label>
                                                    <p>{InvoiceRec && InvoiceRec.shipGst === null || InvoiceRec && InvoiceRec.shipGst === "" || InvoiceRec && InvoiceRec.shipGst === undefined ? "Unregistered" : "Registered"}</p>
                                                </div>
                                            </div>
                                        </div>) : (<div className='supplier_details pt-0 pb-0 mt-3'>
                                            <div className='row'>
                                                <div className='col-lg-5 col-12 suP_div'>
                                                    <div className='sup_2_div'>
                                                        <div>
                                                            <label htmlFor="">Address 1</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.address1}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Name</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.customerName}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Phone</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.phone}</p>
                                                        </div>
                                                    </div>
                                                    <div className='sup_thr_div'>
                                                        <div>
                                                            <label htmlFor="">Pan Number</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.panNumber}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Customer Email</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.emailAdd1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 col-12 suP_div bdr'>
                                                    <div className='sup_2_div'>
                                                        <div>
                                                            <label htmlFor="">Address 2</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.address2}</p>
                                                        </div>
                                                        <div style={{ visibility: 'hidden' }}>
                                                            <label htmlFor="">Purchase Tax TIN NO</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.purchaserTaxTinno}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Email 2</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.emailAdd2}</p>
                                                        </div>
                                                    </div>
                                                    <div className='sup_thr_div'>
                                                        <div>
                                                            <label htmlFor="">Customer Cellular</label>
                                                            <p>{SelectedCustomer && SelectedCustomer.phone}</p>
                                                        </div>
                                                        {/* <div>
                                                        <label htmlFor="">Customer Email</label>
                                                        <p>{SelectedCustomer && SelectedCustomer.emailAdd1}</p>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-12 suP_div thr'>
                                                    <div>
                                                        <label htmlFor="">
                                                            {SelectedCustomer && SelectedCustomer.purchaserTaxTinno ? "B2B" : "B2C"}
                                                        </label>
                                                        <p>
                                                            {SelectedCustomer && SelectedCustomer.purchaserTaxTinno ? "Registered" : "Unregistered"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {((InvoiceRec.invoiceStatus === 1 && InvoiceRec.invRegUnreg) || (InvoiceRec.invoiceStatus !== 1 && InvoiceTypeObj.regUnregFlg)) && (<div className="accordion accordion-flush" id="accordionFlushExample_3_0n">
                        <div className="accordion-item acc_item">
                            <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour_on" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <span>Customer Contract Details</span><i className="fa-solid fa-angle-down"></i>
                            </button>
                            <div id="collapsefour_on" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample_3_0n">
                                <div className="accordion-body All_c_i_acc_bd">
                                    <div className='customer_conract'>
                                        {InvoiceRec.invoiceStatus === 1 ? (<><div className='customer_conract_1'>
                                            <div className=''>
                                                <button disabled={EditFlag === "0"} onClick={() => GetCmsRecords(true)} className='grn_btn'>Customer Contract</button>
                                            </div>
                                            <div className='' style={{ display: 'flex', gap: '10px' }}>
                                                <input disabled={EditFlag === "0"} value={cmsNumber} onChange={(e) => setcmsNumber(e.target.value)} className='search_inp_filed' type="text" name="" id="" placeholder='Enter Contract No.' />
                                                <i disabled={EditFlag === "0"} onClick={() => SearchContractNumberCMS(cmsNumber)} className="fa-solid fa-magnifying-glass search_icon"></i>
                                            </div>
                                            <div className=''>
                                                <label htmlFor="">Customer Name</label>
                                                <p>{InvoiceRec && InvoiceRec.remarketingCustomerName}</p>
                                            </div>
                                            <div className=''>
                                                <label htmlFor="">CMS Contract Number</label>
                                                <p>{InvoiceRec.remarketingCustomerId}</p>
                                            </div>
                                            <div className=''>
                                                <label htmlFor="">GST NO.</label>
                                                <p></p>
                                            </div>
                                        </div>
                                            <div className='customer_conract_1 on'>
                                                <div className=''>
                                                    <label htmlFor="">VIN NO.</label>
                                                    <p>{InvoiceRec && InvoiceRec.reMarkVinno}</p>
                                                </div>
                                                <div className='' style={{ display: 'flex', justifyContent: 'start', gap: "20px" }}>
                                                    <div>
                                                        <label htmlFor="">Registration  No.</label>
                                                        <p>{InvoiceRec && InvoiceRec.vehicleNumber}</p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="">Model</label>
                                                        <p>{InvoiceRec && InvoiceRec.remarkModel}</p>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">PAN Number</label>
                                                    <p>{InvoiceRec && InvoiceRec.reMarkPanNumber}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Start Date</label>
                                                    <p>{InvoiceRec && InvoiceRec.reMarkContractStartDate}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Total Cost</label>
                                                    <p>{InvoiceRec && InvoiceRec.reMarkTotalCostAmount}</p>
                                                </div>
                                            </div></>) : (<>
                                                <div className='customer_conract_1'>
                                                    <div className=''>
                                                        <button disabled={EditFlag === "0"} onClick={() => GetCmsRecords(true)} className='grn_btn'>Customer Contract</button>
                                                        {
                                                            AllInvoiceErr === false && ContractCustomer === '' &&
                                                            <p style={{ fontSize: '12px', color: 'red', fontWeight: '400' }}>Please Select Customer</p>
                                                        }
                                                    </div>
                                                    <div className='' style={{ display: 'flex', gap: '10px' }}>
                                                        <input style={(AllInvoiceErr === false && ContractCustomer === "") ? { borderColor: 'red' } : {}} value={cmsNumber} onChange={(e) => setcmsNumber(e.target.value)} className='search_inp_filed' type="text" placeholder='Enter Contract No.' />
                                                        <i disabled={EditFlag === "0"} onClick={() => SearchContractNumberCMS(cmsNumber)} className="fa-solid fa-magnifying-glass search_icon"></i>
                                                    </div>

                                                    <div className=''>
                                                        <label htmlFor="">Customer Name</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.customerName}</p>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor="">CMS Contract Number</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.cmscontractNumber}</p>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor="">GST NO.</label>
                                                        <p></p>
                                                    </div>
                                                </div>
                                                <div className='customer_conract_1 on'>

                                                    <div className=''>
                                                        <label htmlFor="">VIN NO.</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.vinnumber}</p>
                                                    </div>
                                                    <div className='' style={{ display: 'flex', justifyContent: 'start', gap: "20px" }}>
                                                        <div>
                                                            <label htmlFor="">Registration  No.</label>
                                                            <p>{ContractCustomer && ContractCustomer && ContractCustomer.registrationNo}</p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Model</label>
                                                            <p>{ContractCustomer && ContractCustomer && ContractCustomer.model}</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor="">PAN Number</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.panNumber}</p>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor="">Start Date</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.contractStartDate}</p>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor="">Total Cost</label>
                                                        <p>{ContractCustomer && ContractCustomer && ContractCustomer.totalCostAmount}</p>
                                                    </div>
                                                </div>
                                            </>)}
                                    </div>
                                    <div className='customer_contact_blr'>
                                        <div className='cus_cont_top'>
                                            <div className='custom_cont_div'>
                                                <label htmlFor="">Sales Amount</label>
                                                <input disabled={EditFlag === "0"} value={SalesAmountRegUnReg} onBlur={handleCalculationCall} onChange={handleUpdateAMt} className='search_inp_filed' type="text" name="" placeholder='0' id="" />
                                            </div>
                                            <div className='custom_cont_div'>
                                                <input disabled={EditFlag === "0"} checked={GstFlag === 1} type="radio" onChange={() => { GstFlag === 1 ? setGstFlag(2) : setGstFlag(1) }} onClick={() => { handleCalculationCall(); setMarginAmt(0) }} name="regFlag" placeholder='10000' id="reg" />
                                                <label htmlFor="reg">GST Registered</label>
                                            </div>
                                            <div className='custom_cont_div'>
                                                <input disabled={EditFlag === "0"} checked={GstFlag === 2} type="radio" onChange={() => { GstFlag === 2 ? setGstFlag(1) : setGstFlag(2) }} onClick={handleCalculationCall} name="regFlag" placeholder='10000' id="unreg" />
                                                <label htmlFor="unreg">GST Unregistered</label>
                                            </div>
                                        </div>
                                        {GstFlag === 2 && (<div className='cus_cont_top tw'>
                                            <div className='custom_cont_div_2'>
                                                <label htmlFor="">Number of Quaters</label>
                                                <p>{CalculationResponse.noOfQuaters ? CalculationResponse.noOfQuaters : "0"}</p>
                                            </div>
                                            <div className='custom_cont_div_2'>
                                                <label htmlFor="">Depreciation per quater</label>
                                                <p>{CalculationResponse.dep_per_Qua ? CalculationResponse.dep_per_Qua : "0"}</p>
                                            </div>
                                            <div className='custom_cont_div_2'>
                                                <label htmlFor="">Total Depreciation</label>
                                                <p>{CalculationResponse.totalDep ? CalculationResponse.totalDep : "0"}</p>
                                            </div>
                                            <div className='custom_cont_div_2'>
                                                <label htmlFor="">Assessed Amount</label>
                                                <p>{CalculationResponse.assessed_Amount ? CalculationResponse.assessed_Amount : "0"}</p>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    <div className="accordion accordion-flush" id="accordionFlushExample_3">
                        <div className="accordion-item acc_item">
                            <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <span>Invoice Details</span><i className="fa-solid fa-angle-down"></i>
                            </button>
                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample_3">
                                <div className="accordion-body All_c_i_acc_bd">
                                    <div className='row rw_all_ci_bd'>
                                        <div className='col-lg-3 col-12'>
                                            <p className='i_d_head'>Invoice Item</p>
                                            <select disabled={EditFlag === "0"} className="select_inp_filed" style={{ width: "100%" }} value={CurrentItemIdentifier} onChange={(e) => setCurrentItemidentifier(e.target.value)} aria-label="Default select example">
                                                <option selected value="" >Select</option>
                                                {
                                                    GetItems && GetItems.map((itemdrop, uq) => (
                                                        <option key={uq} value={itemdrop.itemMasterIdentifier}>
                                                            {itemdrop.itemName}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                AllInvoiceErr === false && CurrentItemIdentifier === '' &&
                                                <p style={{ fontSize: '12px', color: 'red' }}>Please Provide valid Information</p>
                                            }

                                        </div>
                                        <div className='col-lg-9 col-12'>
                                            <p className='i_d_head'>Description of Goods / Service</p>
                                            <textarea disabled={EditFlag === "0"} value={ItemData.itemDescription} className='input_txt_are'></textarea>
                                        </div>
                                    </div>
                                    <div className='row rw_all_ci_tw'>
                                        <div className='col-lg-3 col-12 amt_serch'>
                                            <label htmlFor="amount">Amount</label>
                                            <input disabled={EditFlag === "0"} style={{ width: '90px' }} value={InvoiceAmt} onBlur={handleCalculationCall} onChange={(e) => { setInvoiceReqObj({ ...InvoiceReqObj, invoice_Amount: e.target.value }); setInvoiceAmt(e.target.value) }} id='amount' className="search_inp_filed" type="text" name="" placeholder=""></input>
                                            {
                                                AllInvoiceErr === false && InvoiceAmt === 0 || InvoiceAmt === '' &&
                                                <p style={{ fontSize: '12px', color: 'red' }}>Please Provide valid Information</p>
                                            }
                                        </div>



                                        <div className='col-lg-9 col-12 amt_radio'>
                                            <div>
                                                <input disabled={EditFlag === "0"} id='tax_val' checked={CalculateType === '1'} className="radio_all_c" type="radio" value='1' onClick={handleCalculateType} name="taxType" placeholder=""></input>
                                                <label htmlFor="tax_val">Taxable Value</label>
                                            </div>
                                            <div>
                                                <input disabled={EditFlag === "0"} id='amt_val' checked={CalculateType === '3'} className="radio_all_c" type="radio" value='3' onClick={handleCalculateType} name="taxType" placeholder=""></input>
                                                <label htmlFor="amt_val">Amount(Incl. GST & TCS)</label>
                                            </div>
                                            <div>
                                                <input disabled={EditFlag === "0"} id='amt_inc' checked={CalculateType === '2'} className="radio_all_c" type="radio" value='2' onClick={handleCalculateType} name="taxType" placeholder=""></input>
                                                <label htmlFor="amt_inc">Amount(Incl. GST & No TCS)</label>
                                            </div>
                                            <div>
                                                <input disabled={EditFlag === "0"} id='amt_exc' checked={CalculateType === '4'} className="radio_all_c" type="radio" value='4' onClick={handleCalculateType} name="taxType" placeholder=""></input>
                                                <label htmlFor="amt_exc">Amount(Incl. GST & Excl. TCS)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row rw_all_ci_tr'>
                                        <div className='col-lg-10 col-12'>
                                            <table className='inc_amt_table'>
                                                <tr>
                                                    <th>HSN/SAC <br /> Code</th>
                                                    <th>Taxable Value <br /> (INR)</th>
                                                    <th>Unit of <br /> Measurement</th>
                                                    <th>Unit <br /> Price</th>
                                                    <th>Value <br /> (INR)</th>
                                                    {InvoiceTypeObj.regUnregFlg && <th>Margin<br />Amount</th>}
                                                </tr>
                                                <tr>
                                                    <td><label className='Invoice_hsncode' style={{ width: "120px" }} htmlFor="">{HSNCODE}</label></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.taxableValue ? CalculationResponse.taxableValue : "0"} type="text" name="" placeholder=""></input></td>
                                                    <td>
                                                        <select className="select_inp_filed"
                                                            style={{ width: "100%" }}
                                                            value={CurrentUnitIdentifier}
                                                            disabled={EditFlag === "0"}
                                                            onChange={(e) => setCurrentUnitIdentifier(e.target.value)}
                                                            aria-label="Default select example">
                                                            <option selected value="" >Select</option>
                                                            {
                                                                GetUnits && GetUnits.map((unit, uq) => (
                                                                    <option key={uq} value={unit.unitMeasurementConfigurationIdentifier}>
                                                                        {unit.unitMeasurementName}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value="0" type="text" name="" placeholder="UnitPrice"></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.value ? CalculationResponse.value : "0"} type="text" name="value" placeholder=""></input></td>
                                                    {InvoiceTypeObj.regUnregFlg && <td>
                                                        <input id='' className="search_inp_filed in_tb" disabled={EditFlag === "0"} value={MarginAmt ? MarginAmt : "0"} onChange={(e) => setMarginAmt(e.target.value)} onBlur={handleCalculationCall} type="text" name="Margin" placeholder=""></input>
                                                    </td>}

                                                </tr>
                                            </table>
                                            <table className='inc_amt_table_tw'>
                                                <thead>
                                                    <tr className='top_te_head'>
                                                        <th colspan="2">CGST</th>
                                                        <th colspan="2">SGST</th>
                                                        <th colspan="2">IGST</th>
                                                        <th colspan="2">Cess</th>
                                                    </tr>
                                                    <tr className='top_tw_head'>
                                                        <th>Rate %</th>
                                                        <th>Amount</th>
                                                        <th>Rate %</th>
                                                        <th>Amount</th>
                                                        <th>Rate %</th>
                                                        <th>Amount</th>
                                                        <th>Rate %</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.cgstRate ? CalculationResponse.cgstRate : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.cgstAmt ? CalculationResponse.cgstAmt : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.sgstRate ? CalculationResponse.sgstRate : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.sgstAmt ? CalculationResponse.sgstAmt : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.igstRate ? CalculationResponse.igstRate : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.igstAmt ? CalculationResponse.igstAmt : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.cessRate ? CalculationResponse.cessRate : "0"} type="text" name="" placeholder=""></input></td>
                                                        <td><input id='' disabled className="search_inp_filed in_tb" value={CalculationResponse.cessAmt ? CalculationResponse.cessAmt : "0"} type="text" name="" placeholder=""></input></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='col-lg-2 col-12 table_amt_ttl'>
                                            <div>
                                                <p className='amt_wht'>Total Value</p>
                                                <p className='amt_dig'>{CalculationResponse.totalValue ? CalculationResponse.totalValue : "0"}</p>
                                            </div>
                                            <div>
                                                <p className='amt_wht'>TCS{CalculationResponse.tcsRate && CalculationResponse.tcsRate !== "0" ? (" " + CalculationResponse.tcsRate + "%") : ""}</p>
                                                <p className='amt_dig'>{CalculationResponse.tcsAmt ? CalculationResponse.tcsAmt : "0"}</p>
                                            </div>
                                            <div>
                                                <p className='amt_wht'>Total Invoice Value</p>
                                                <p className='amt_dig'>{CalculationResponse.totalInvoiceValue ? CalculationResponse.totalInvoiceValue : "0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='amt_last_para'>
                                        <p >Tax Is Payable On Reverse Charge</p>
                                        <select id='ReverseCharge' disabled={EditFlag === "0"} value={ReverseCharge} onChange={(e) => setReverseCharge(e.target.value)} style={{ width: '60px' }} className="select_inp_filed" aria-label="Default select example">
                                            <option value={false} selected>No</option>
                                            <option value={true}>Yes</option>
                                        </select>
                                    </div>
                                    <div className='amt_last_para_tw'>
                                        <p >Total Invoice Value (In Figures) : <span><b>{CalculationResponse.totalInvoiceValue ? CalculationResponse.totalInvoiceValue : "0"}</b></span></p>
                                    </div>
                                    <div className='amt_last_para_tr'>
                                        <p >Total Invoice Value (In Words) : <span><b>{CalculationResponse.totalInvoiceValueInWords ? CalculationResponse.totalInvoiceValueInWords : "ZERO"}</b></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div >

                    <div className='mt-3 top_hd_aci' style={{ display: 'flex', gap: '10px' }}>
                        {
                            InvoiceRec.invoiceStatus !== 1 ? (
                                EditFlag === "1" ? (MakerFlag && <>
                                    <button onClick={() => OnInvoiceUpdateSubmit(InvoiceRec.invoiceIdentifier)} className='mx-2 grn_btn'>Submit</button>
                                    <button onClick={cancelReset} className='diable_btn_edit'>Cancel</button>
                                </>) : (CheckerFlag && InvoiceRec.invoiceStatus !== 2 && <>
                                    <button onClick={ApproveModal} className='grn_btn'>Approve</button>
                                    <button onClick={rejectModal} className='grn_btn'>Reject</button>
                                    <button onClick={() => SetShow(1)} className='diable_btn_edit'>Cancel</button>
                                </>)
                            ) : ''

                        }
                    </div>

                    <div>
                        {InvoiceRec.invoiceStatus === 2 && (
                            <Invoice_rejected_table />
                        )}
                    </div>
                    <div className='a_c_i_btm_btn'>
                        <button onClick={() => SetShow(1)} className='grn_btn'>Go Back</button>
                    </div>
                    <input type="text" id='invoice_updating' hidden data-bs-toggle="modal" data-bs-target="#invoice_updating_mdl" />
                    <div className="modal fade mdl_e_fade" id="invoice_updating_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                    Are you sure you want to Generate the Invoice?
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => SubmitInvoice(InvoiceRec.invoiceIdentifier)} className='grn_btn'>Yes</button>
                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <input type="text" id='invoice_Approval' hidden data-bs-toggle="modal" data-bs-target="#invoice_Approval_mdl" />

                    <div className="modal fade mdl_e_fade" id="invoice_Approval_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                    Are you sure you want to approve the Invoice? Invoice once approved cannot be edited.
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => handleApproveInvoice(InvoiceRec.invoiceIdentifier)} className='grn_btn'>Yes</button>
                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <input type="text" id='Approval_success_model' hidden data-bs-toggle="modal" data-bs-target="#Approval_Success_mdl" />
                    <div className="modal fade mdl_e_fade" id="Approval_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                Invoice has been created successfully
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <input type="text" id='Approval2_success_model' hidden data-bs-toggle="modal" data-bs-target="#Approval2_Success_mdl" />
                    <div className="modal fade mdl_e_fade" id="Approval2_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                Invoice has been approved successfully
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <input type="text" id='Reject_success_model' hidden data-bs-toggle="modal" data-bs-target="#Reject_Success_mdl" />
                    <div className="modal fade mdl_e_fade" id="Reject_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                            <div className="modal-content mdl_cont">
                                <div className="modal-header mdl_header">
                                    <h5 className="modal-title mdl_title">Alert</h5>
                                    <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mdl_bdy">
                                    Invoice has been rejected successfully.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>


                    </div>



                </div>
            </div>



        </div >


    )
}

export default All_invoice_view