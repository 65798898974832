import React, { useContext, useEffect, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'


const Item_master_edit = () => {
    const { setitemshow, ItemRegFlag, setItemRegFlag, createitemmaster, handleitemcreatechange, itemdropdowncreate, Itemmasterenabled, Itemmasterdisabled, itemeditflag,
        errfieldone, seterrfieldone, handleitemeditsaveinput, Actionbutton, handledeleteitemmaster, setitemeditview, itemeditview,handleEditInserttwo } = useContext(E_invoice_context)

    const enablemodelclick = () => {
        document.getElementById('model_triger_enable').click();
    }
    const disablemodelclick = () => {
        document.getElementById('model_triger_disable').click();
    }

    const deletemodelclick = () => {
        document.getElementById('succces_go_back').click();
    }
    const handleEditInsert = () => {
        seterrfieldone(true);
        handleitemeditsaveinput()
    }

    const handleitemcancel = () => {
        setitemshow(1)
    }

    const handleInvoiceTypeChange = (e) => {
        handleitemcreatechange(e)
        setItemRegFlag(itemdropdowncreate && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === e.target.value))[0] && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === e.target.value))[0].reg_Unreg_Flg)
    }

    useEffect(() => {
        setItemRegFlag(itemdropdowncreate && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === createitemmaster.invoiceTypeIdentifier))[0] && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === createitemmaster.invoiceTypeIdentifier))[0].reg_Unreg_Flg)
    }, [createitemmaster])


    // console.log(ItemRegFlag)
    // console.log(itemdropdowncreate)
    return (

        <div style={{ border: '1px solid #eaeaea', overflow: 'hidden' }}>



            <input type="text" id='model_triger_enable' hidden data-bs-toggle="modal" data-bs-target="#model_one_enable" />
            <input type="text" id='model_one_enable_success' hidden data-bs-toggle="modal" data-bs-target="#model_one_enable_success_bs" />


            <input type="text" id='model_triger_disable' hidden data-bs-toggle="modal" data-bs-target="#model_one_disable" />
            <input type="text" id='model_one_disable_success' hidden data-bs-toggle="modal" data-bs-target="#model_one_disable_success_bs" />


            <div className="modal fade mdl_e_fade" id="model_one_enable" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to enable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={Itemmasterenabled} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" id="model_one_enable_success_bs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">

                        Item Master has been enabled successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" id="model_one_disable" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to disable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={Itemmasterdisabled} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" id="model_one_disable_success_bs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Item Master has been disabled successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="creat_c_m c_s_c">
                <i onClick={() => setitemshow(1)} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>Create Item</p>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', width: '88%' }}>
                    {
                        itemeditview.Edit === true &&
                        (<button onClick={() => setitemeditview({ ...itemeditview, Edit: false })} className="grn_btn">Edit</button>)
                    }
                    {/* {
                        itemeditview.enable === false ? (
                            <button onClick={enablemodelclick} className='diable_btn_edit'>{Actionbutton.disable}</button>
                        ) : (
                            <button onClick={disablemodelclick} className='grn_btn'>{Actionbutton.enable}</button>
                        )
                    } */}
                    {
                        itemeditflag === false ? (
                            <button onClick={enablemodelclick} className='grn_btn'>{Actionbutton.enable}</button>
                        ) : (
                            <button onClick={disablemodelclick} className='diable_btn_edit'>{Actionbutton.disable}</button>
                        )
                    }
                    <button onClick={deletemodelclick} className='diable_btn_edit'>{Actionbutton.delete}</button>
                </div>
            </div>
            <div className='create_itm'>
                <div className='row'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Invoice Type<span className="mandatory_star2">*</span></label>
                        <select disabled={itemeditview.Edit === true} className="select_inp_filed" style={{ width: '100%' }} name='invoiceTypeIdentifier' value={createitemmaster.invoiceTypeIdentifier} onChange={handleInvoiceTypeChange} aria-label="Default select example">

                            <option selected value=''>Select</option>
                            {
                                itemdropdowncreate && itemdropdowncreate.map((itemdro, uq) => (
                                    <option key={uq} value={itemdro.invoiceTypeConfigurationIdentifier}>
                                        {itemdro.invoiceTypeName}
                                    </option>
                                ))
                            }
                        </select>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.invoiceTypeIdentifier === '' ? ('provide information for the field') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Item Name<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} className="search_inp_filed in_tb" type="text" name="itemName" value={createitemmaster.itemName} onChange={handleitemcreatechange} placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemName === '' || createitemmaster.itemName.length > 50 ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Item Code<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} className="search_inp_filed in_tb" type="text" name="itemCode" value={createitemmaster.itemCode} onChange={handleitemcreatechange} placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemCode === '' || createitemmaster.itemCode.length > 50 ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                </div>
                <div className='item_dexcription mt-4 '>
                    <label className="label_w_s" htmlFor="a_c_i_pan">Item Description<span className="mandatory_star2">*</span></label>
                    <textarea disabled={itemeditview.Edit === true} className='input_txt_are' name="itemDescription" value={createitemmaster.itemDescription} onChange={handleitemcreatechange} id="" rows="2"></textarea>
                    {
                        errfieldone === false &&
                        <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemDescription === '' ? ('provide information for the field') : ('')}</p>
                    }

                </div>
            </div>

            <div className="creat_c_m c_s_c">
                <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST Rates</p>
            </div>
            {
                ItemRegFlag ? (<div className='gst_rowates'>

                    <div className='col-lg-4 gst_rates'>
                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 1<span className="mandatory_star2">*</span></label>
                            <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.hsncode1} type="text" name="hsncode1" placeholder=""></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode1 === '' || createitemmaster.hsncode1 === null || createitemmaster.hsncode1 === undefined ? ('provide information for the field') : ('')}</p>
                            }
                        </div>

                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 2<span className="mandatory_star2">*</span></label>
                            <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode2} name="hsncode2" placeholder=""></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode2 === '' || createitemmaster.hsncode2 === null || createitemmaster.hsncode2 === undefined ? ('Please Provide valid Information') : ('')}</p>
                            }
                        </div>
                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 3<span className="mandatory_star2">*</span></label>
                            <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode3} name="hsncode3" placeholder="" maxLength={8}></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode3 === '' || createitemmaster.hsncode3 === null || createitemmaster.hsncode3 === undefined ? ('Please Provide valid Information ') : ('')}</p>
                            }
                        </div>

                    </div>

                </div>) : (
                    <div className='gst_rowates'>
                        <div className='col-lg-4 gst_rates'>
                            <div>
                                <label className="label_w_s" htmlFor="a_c_i_pan">HSN code<span className="mandatory_star2">*</span></label>
                                <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode} name="hsncode" placeholder="" maxLength={8}></input>
                                {
                                    errfieldone === false &&
                                    <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode === '' || createitemmaster.hsncode === null || createitemmaster.hsncode === undefined ? ('Please Provide valid Information ') : ('')}</p>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="creat_c_m c_s_c row">
                <div className='col-lg-6 col-12'>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST Rates</p>
                </div>
                <div className='col-lg-6 col-12'>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST - Interstate</p>
                </div>
            </div>
            <div className='gst_instratae'>
                <div className='row'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">CGST (%)<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.cgstrate} type="text" name="cgstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.cgstrate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">SGST (%)<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.sgstrate} type="text" name="sgstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.sgstrate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">IGST (%)<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.igstrate} type="text" name="igstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.igstrate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Cess Rate (%)<span className="mandatory_star2">*</span></label>
                        <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.ccessRate} type="number" name="ccessRate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.ccessRate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">SAP GL</label>
                        <input disabled={itemeditview.Edit === true} onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.sapgl} type="text" name="sapgl" placeholder=""></input>
                        {/* {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.sapgl === '' ? ('provide information for the field') : ('')}</p>
                        } */}

                    </div>
                </div>
            </div>
            {
                itemeditview.Edit === false && (
                    ItemRegFlag === true ? (
                        <div className="p_T_c_btn mt-4 mb-4">
                            <button className="grn_btn" onClick={handleEditInsert}>Save</button>
                            <button onClick={handleitemcancel} className="diable_btn_edit">Cancel</button>
                        </div>
                    ) : (
                        <div className="p_T_c_btn mt-4 mb-4">
                            <button className="grn_btn" onClick={handleEditInserttwo}>Save</button>
                            <button onClick={handleitemcancel} className="diable_btn_edit">Cancel</button>
                        </div>
                    )
                )
            }
        </div >

    )
}

export default Item_master_edit