import { createContext, useEffect, useState } from "react";
import AuthService from "./Service/Authservice";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';


const E_invoice_context = createContext();

const ContextProvider = ({ children }) => {

    //Note: Write a function particular Section
    //Do not Combine one section to another section
    //Then all will collapse

    const [Modules, setModules] = useState([0])
    const [suplierstatecode, setsuplierstatecode] = useState([])
    const [suplierinserstatecode, setsuplierinsertstatecode] = useState()
    const [show, SetShow] = useState(1)
    const [Createcs, Setcreatecs] = useState(1)
    const [Bill, SetBill] = useState(1)
    const [Ship, SetShip] = useState(1)
    const [config, setconfig] = useState(1)
    const [cmsNumber, setcmsNumber] = useState('')


    const Edit_click = () => {
        Setcreatecs(2)
        SetShow(5)
    }
    var customer_identifier = "";
    // supply config starts
    const Getstatecodeconfig = () => {
        AuthService.GetServiceCall(`api/StateCodes/StateCode?page=${0}&pageSize=${0}&sortField=${'StateName'}&isAscending=${false}`)
            .then((res) => {
                if (res) {
                    setsuplierstatecode(res.responseData)
                }
            })
    }
    const Getinseretstatecodeconfig = () => {
        AuthService.GetServiceCall('api/invoiceTypeConfigurations/SupplierNameInsertDropDown')
            .then((res) => {
                if (res) {
                    setsuplierinsertstatecode(res.responseData)
                }
            })
    }
    // supply config ends

    //Login_function 
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [Err, Seterr] = useState('')
    const [isAuthorized, setisAuthorized] = useState(true)

    const Login_obj = {
        "userName": username,
        "password": password
    }

    const encryptData = (name, data) => {
        const secretKey = 'ZGF5c2NoZWR1bGU=';
        const ciphertext = CryptoJS.AES.encrypt(`${data}`, secretKey).toString();
        sessionStorage.setItem(name, ciphertext);
    };

    const decryptData = (name) => {
        const secretKey = 'ZGF5c2NoZWR1bGU=';
        const ciphertext = sessionStorage.getItem(name);
        if (ciphertext) {
            const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
            const originalData = bytes.toString(CryptoJS.enc.Utf8);
            return originalData
        }
        return "";
    };

    const decodeJWT = (token) => {
        if (token) {
            let tokenParts = token.split('.');
            let payload = JSON.parse(atob(tokenParts[1]));
            return payload;
        }
    }
    const decodedPayload = decodeJWT(decryptData('Jwt_Token'));

    var CurrentUser = "";
    var sessionStartTime = new Date();
    var sessionEndTime = new Date();
    if (decodedPayload && decodedPayload.Identifier && decodedPayload.Identifier !== undefined && decodedPayload.Identifier !== null) {

        CurrentUser = decodedPayload.Identifier;

        sessionStartTime = decodedPayload.iat ? new Date(decodedPayload.iat * 1000) : new Date();
        sessionEndTime = (decodedPayload.iat && decodedPayload.exp) ? new Date((decodedPayload.iat + decodedPayload.exp) * 1000) : new Date();
    }

    const [isAdmin, setisAdmin] = useState(false)
    const [invoicingFlag, setinvoicingFlag] = useState(false)
    const [proformaFlag, setproformaFlag] = useState(false)
    const [moduleAccess, setmoduleAccess] = useState([])
    const [invoicing, setinvoicing] = useState([])
    const [invoiceAccess, setinvoiceAccess] = useState([])
    const [proforma, setproforma] = useState([])
    const [proformaAccess, setproformaAccess] = useState([])

    useEffect(() => {
        if (CurrentUser) {
            fetchUserAccess();
            Getinvoicetype();
            Getinvoicetypedropdown();
        }
    }, [CurrentUser]);

    const fetchUserAccess = async () => {
        if (CurrentUser) {
            try {
                const res = await AuthService.GetServiceCall(`api/Invoice/DoGetUserAccessDetails?UserIdentifier=${CurrentUser}`);
                // console.log('fetchUserAccess', res)
                if (res && res !== '' && res.status === 'Success' && res.data) {
                    setisAdmin(res.data.isAdmin);
                    setinvoicingFlag(res.data.invoicingFlag);
                    setproformaFlag(res.data.proformaFlag);
                    setmoduleAccess(res.data.moduleAccess || []);
                    setinvoicing(res.data.invoicing || []);
                    setinvoiceAccess(res.data.invoiceAccess || []);
                    setproforma(res.data.proforma || []);
                    setproformaAccess(res.data.proformaAccess || []);
                }
            } catch (err) {
                // console.error('Error:', err);
            }
        }
    };

    const Navigate = useNavigate()
    const handlesingin = (e) => {
        e.preventDefault()
        AuthService.PostServiceCall('api/Login', Login_obj)
            .then((res) => {
                if (res && res !== undefined && res !== null) {
                    // console.log(res)
                    setisAuthorized(res.isAuthorized)
                    if (res.isAuthorized === false) {
                        Seterr('Invalid User. Please try again!')
                    }
                    if (res && res !== '' && res.isAuthorized) {
                        setisAuthorized(res.isAuthorized)
                        if (res.isAuthorized === false) {
                            Seterr('Invalid User. Please try again!')
                        }
                        else {
                            if (res.userType === "Admin") {
                                setModules([0])
                            }
                            else {
                                setModules(res.moduleAccess)
                                encryptData("userModuleAccess", JSON.stringify(res.moduleAccess) || [])
                            }
                            // console.log(res.authToken);

                            // encryptData("identifier", res.identifier);
                            // encryptData("userType", res.userType);
                            // encryptData("Username", res.userName);
                            encryptData("Jwt_Token", res.authToken);
                            fetchUserAccess();

                            Navigate('/All_invoice');
                            GetAllInvoices();
                        }
                    }
                }
            }).catch((err) => console.log("Error:", err));
    }


    const GetLoginDetails = (code) => {
        // console.log(code, 'method')
        AuthService.GetServiceCall('api/Login/SSOLogin?code=' + code)
            .then((res) => {
                if (res && res !== undefined && res !== null) {
                    // console.log(res)
                    setisAuthorized(res.isAuthorized)
                    if (res.isAuthorized === false) {
                        Navigate('/unauthorized')
                    }
                    if (res && res !== '' && res.isAuthorized) {
                        setisAuthorized(res.isAuthorized)
                        if (res.isAuthorized === false) {
                            Seterr('Invalid User. Please try again!')
                        }
                        else {
                            if (res.userType === "Admin") {
                                setModules([0])
                            }
                            else {
                                setModules(res.moduleAccess)
                                encryptData("userModuleAccess", JSON.stringify(res.moduleAccess) || [])
                            }
                            encryptData("Jwt_Token", res.authToken);
                            Navigate('/All_invoice');
                            fetchUserAccess();
                            GetAllInvoices();
                        }
                    }
                }
            }).catch((err) => {
                // console.log("Error:", err);
                Navigate('/unauthorized')
            });
    };


    useEffect(() => {
        if (CurrentUser) {
            const moduleAccessString = decryptData('userModuleAccess');
            try {
                if (moduleAccessString) {
                    const parsedModuleAccess = JSON.parse(moduleAccessString);
                    if (parsedModuleAccess && Array.isArray(parsedModuleAccess)) {
                        setModules(parsedModuleAccess);
                    } else {
                        setModules([0]);
                    }
                }
            } catch (error) {
                // console.error('Error parsing JSON:', error);
                setModules([0]);
            }
        }
    }, [CurrentUser]);

    //#region user_master_configration_start
    // userMaster View
    const [userPages, setUserPages] = useState(1)
    const [UserRecords, setUserRecords] = useState([])
    const [Userresponsed, setuserresponsed] = useState()
    const [UserPaginations, SetUserPaginations] = useState({ Current_page: 1 });
    const [usemaster_config, setuser_master_config] = useState([])
    const [InvoiceTypes, setInvoiceTypes] = useState({})
    const [ProfomaTypes, setProfomaTypes] = useState({})
    const [Edituser, setedituser] = useState(false)
    const [MailCheckMark, setMailCheckMark] = useState([]);     // mail send
    const [AllCheckMark, setAllCheckMark] = useState(false);     // mail send

    const [GetRecords, setGetRecords] = useState({
        identifier: "",
        emailId: "",
        startPage: 1,
        noofPages: 10,
        sort: "",
        sortColumn: "",
        search: ""
    })

    const defaultUser = {
        "identifier": "",
        "userName": "",
        "emailId": "",
        "userPassword": "",
        "departmentName": "",
        "invoiceTypeAccess": InvoiceTypes,
        "profomaInvoice": ProfomaTypes,
        "invoiceModuleAccess": []
    }

    const [UserView, setUserView] = useState(defaultUser);
    const [globalFormat, setglobalFormat] = useState({})
    var commonFormat = {}
    const UserpageNumbers = [];
    var userTemp = [];

    useEffect(() => {
        if (CurrentUser) {
            InvoiceTypesRecent();
            setUserView(1)
            GetAllUnitMeasures()
        }
    }, [CurrentUser])

    const InvoiceTypesRecent = () => {
        AuthService.PostServiceCall(`api/UserMasters/GetConfiguration?identifier=${CurrentUser}`)
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.invoicetype) {
                    setuser_master_config(res.invoicetype)
                    // console.log(res.invoicetype)
                    for (let i = 0; i < res.invoicetype.length; i++) {
                        commonFormat = {
                            ...commonFormat,
                            [res.invoicetype[i].invoiceTypeConfigurationIdentifier]: {
                                "invoiceTypeIdentifier": res.invoicetype[i].invoiceTypeConfigurationIdentifier,
                                "markerFlag": false,
                                "reportFlag": false,
                                "viewFlag": false,
                                "checkerFlag": false,
                            }
                        }
                    }
                    // console.log(commonFormat)
                    setglobalFormat(commonFormat);
                    setInvoiceTypes(commonFormat);
                    setProfomaTypes(commonFormat);
                }
            })
            .catch((err) => console.log("Error:", err));
    }

    const handleCreateNew = () => {
        setUserView(defaultUser)
        setInvoiceTypes(globalFormat)
        setProfomaTypes(globalFormat)
        setUserPages(2)
    }

    const GetUserRecords = () => {
        if (CurrentUser)
            AuthService.PostServiceCall(`api/UserMasters/GetUserMastersDetails`, GetRecords)
                .then((res) => {
                    if (res.responseCode === "200") {
                        setUserRecords(res.usermasterDetails)
                        setuserresponsed(res.recordCount)
                    }
                })
                .catch((err) => console.log("Error:", err));
    }

    for (let i = 1; i <= Math.ceil(Userresponsed && Userresponsed / 10); i++) {
        UserpageNumbers.push(i);
    }

    const UserhandlePageChange = (pageNumber) => {
        setGetRecords({ ...GetRecords, startPage: pageNumber })
        SetUserPaginations({ ...UserPaginations, Current_page: pageNumber });
    };
    const [userfielderr, setuserfielderr] = useState({ emailerr: false, fielderr: false })

    const validateemail = (email) => {
        const emailrgx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidemail = emailrgx.test(email);
        // console.log(isValidemail)
        setuserfielderr({ ...userfielderr, emailerr: isValidemail })
    };





    const handleUserView = (Useridentifier) => {
        let getObj = {
            identifier: Useridentifier,
            emailId: "",
            startPage: 1,
            noofPages: 10,
            sort: "",
            sortColumn: "",
            search: ""
        }
        setInvoiceTypes(globalFormat);
        setProfomaTypes(globalFormat);
        var invChecks = globalFormat
        var proChecks = globalFormat
        AuthService.PostServiceCall(`api/UserMasters/GetUserMastersDetails`, getObj)
            .then((res) => {
                if (res.responseCode === "200") {
                    userTemp = res.usermasterDetails[0];
                    commonFormat = globalFormat
                    // console.log("View", userTemp)
                    setedituser(true)
                    userTemp.invoiceTypeAccess.forEach((access, index) => {
                        const type = access.moduleId === 6 ? 'invoice' : access.moduleId === 7 ? 'profoma' : '';
                        if (type) {
                            const invoiceTypeConfigurationIdentifier = access.invoiceTypeIdentifier;
                            if (type === 'invoice') {
                                invChecks = {
                                    ...invChecks,
                                    [invoiceTypeConfigurationIdentifier]: {
                                        "invoiceTypeIdentifier": invoiceTypeConfigurationIdentifier,
                                        "markerFlag": access.makerFlag,
                                        "reportFlag": access.reportsFlag,
                                        "viewFlag": access.viewFlag,
                                        "checkerFlag": access.checkerFlag
                                    }
                                }
                            }
                            if (type === 'profoma') {
                                proChecks = {
                                    ...proChecks,
                                    [invoiceTypeConfigurationIdentifier]: {
                                        "invoiceTypeIdentifier": invoiceTypeConfigurationIdentifier,
                                        "markerFlag": access.makerFlag,
                                        "reportFlag": access.reportsFlag,
                                        "viewFlag": access.viewFlag,
                                        "checkerFlag": access.checkerFlag
                                    }
                                }
                            }
                        }
                    });



                    setUserView({
                        ...UserView,
                        identifier: userTemp.identifier,
                        userName: userTemp.userName,
                        emailId: userTemp.emailId,
                        departmentName: userTemp.departmentName,
                        userPassword: "",
                        invoiceTypeAccess: invChecks,
                        profomaInvoice: proChecks,
                        invoiceModuleAccess: userTemp.moduleAccess
                    })
                    setInvoiceTypes(invChecks);
                    setProfomaTypes(proChecks);

                    validateemail(userTemp.emailId)
                    setUserPages(3)
                }
            })
            .catch((err) => console.log("Error:", err));

    }



    const handleUserSearch = () => {
        UserhandlePageChange(1)
        SetUserPaginations({ ...UserPaginations, Current_page: UserPaginations.Current_page = 1 });
        setGetRecords({ ...GetRecords, startPage: GetRecords.pageNumber = 1 })
        GetUserRecords();
    }


    //#endregion user_master_configration_end


    //Report Dharshan Starts
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
    const [InvoiceDataMaster, setInvoiceDataMaster] = useState({ invoiceTypeIdentifier: '', startDate: '', endDate: '' });
    const [InvoiceFileData, setInoiceFileData] = useState();
    const handlestartdate = (date) => {
        setstartdate(date);
        setInvoiceDataMaster({ ...InvoiceDataMaster, startDate: date })
    }
    const handleenddate = (date) => {
        setenddate(date);
        setInvoiceDataMaster({ ...InvoiceDataMaster, endDate: date })
    }
    const handle_invoice_download_data = (e) => {
        e.preventDefault();
        //const { name, value } = e.target;
        setInvoiceDataMaster({ ...InvoiceDataMaster, invoiceTypeIdentifier: e.target.value });
    }
    const handle_download_data = () => {
        // document.getElementById('invoice_type').style.borderColor = "#9b9b9b";
        document.getElementById('startdate').style.borderColor = "#9b9b9b";
        document.getElementById('enddate').style.borderColor = "#9b9b9b";
        // console.log(InvoiceDataMaster);
        // if (InvoiceDataMaster.invoiceTypeIdentifier === "") {
        //     document.getElementById('invoice_type').style.borderColor = "#ff0000";
        // }
        if (InvoiceDataMaster.startDate === "") {
            document.getElementById('startdate').style.borderColor = "#ff0000";
        } else if (InvoiceDataMaster.endDate === '') {
            document.getElementById('enddate').style.borderColor = "#ff0000";
        }
        else {
            downloadInvoice();
        }
    }

    const downloadInvoice = () => {
        AuthService.PostServiceCall('api/Excel/DownloadInvoiceExcelReport', InvoiceDataMaster)
            .then((res) => {
                //   console.log(res);
                if (res && res !== '' && res.responseCode === "200") {
                    const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.basestring}`;
                    //   console.log(href);
                    // Create a download link and trigger a click event
                    const link = document.createElement('a');
                    //   console.log(link);
                    link.href = href;
                    link.download = res.filename; // Set the desired file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                    setstartdate(null)
                    setenddate(null)
                    setInvoiceDataMaster({ ...InvoiceDataMaster, invoiceTypeIdentifier: '' });
                }
                if (res && res != '' && res.responseCode === "404") {
                    document.getElementById('no_records').click();
                    setstartdate(null)
                    setenddate(null)
                    setInvoiceDataMaster({ ...InvoiceDataMaster, invoiceTypeIdentifier: '' });
                }
            })
            .catch((err) => console.log("Error", err));
    }

    //Reports Dharshan Ends


    // indu Invoice Email List  starts

    const [Ascending, setAscending] = useState(false);
    const [columnName, setcolumnName] = useState();

    const handleClick = (columnName) => {
        // console.log(Ascending);
        setAscending(!Ascending);
        setcolumnName(columnName)
    }

    const handleAscendingClick = () => {
        setAscending(true);
        setcolumnName()
    }
    // indu Invoice Email List  Ends

    // All Creadit Dharshan Starts
    const [creditPagination, setCreditPagination] = useState({ Current_page: 1 });
    const [creditenabled, setCreditenabled] = useState(4);
    const [creditsupplierdropdown, setcreditsupplierDropdown] = useState('');
    const [creditsearchvalue, setcreditsearchvalue] = useState('');
    const [creditinvoicetypedropdown, setcreditinvoicetypedropdown] = useState('');
    const [creditinvoicedata, setcredeitinvoicedata] = useState();
    const [creditshow, setCreditShow] = useState(1);
    const [creditdata, setcreditdata] = useState();
    const [creditresponsedata, setcreditresponsedata] = useState();
    const [creditviewdata, setcreditviewdata] = useState();
    const [creditcalculatetype, setcreditcalculatetype] = useState();
    const [creditinvoicetype, setcredtitinvoicetype] = useState('');
    const [creditsuppliertype, setcreditsuppliertype] = useState('');
    const [creditinvoiceamnt, setcreditinvoiceamnt] = useState();
    const [creditsuppcode, setcreditsuppcode] = useState('');
    const [creditInvoiceIdentifier, setcreditInvoiceIdentifier] = useState('');
    const [creditgstflag, setcreditgstflag] = useState();
    const [credititem, setcredititem] = useState('');
    const [creditcustomercode, setcreditcustomercode] = useState('');
    const [creditsalesamnt, setcreditsalesamnt] = useState();
    const [creditcalculatedata, setcreditcalculatedata] = useState();
    const [creditcontractdata, setcreditcontractdata] = useState()
    const [creditIsProfoma, setcreditIsProfoma] = useState(false)
    // const[creditinvoicedata,setcreditinvoicedata] = useState();

    const creditpagenumbers = [];
    for (let i = 1; i <= Math.ceil(creditresponsedata && creditresponsedata / 10); i++) {
        creditpagenumbers.push(i);
    }
    const credithandlePageChange = (pageNumber) => {
        setCreditPagination({ ...creditPagination, Current_page: creditPagination.Current_page = pageNumber });
        setcreditinvoicerecord({ ...creditinvoicerecord, currentPage: creditPagination.Current_page })
    };

    const handlecredit_enable_value = (event) => {
        //setcreditEnablevalue(event.target.value)
        //   console.log(event.target.value);
        if (event.target.value === "1") {
            setCreditenabled(1);
        } else if (event.target.value === "2") {
            setCreditenabled(2);
        } else if (event.target.value === "3") {
            setCreditenabled(3);
        }
        else {
            setCreditenabled(4);
        }
    };


    // useEffect(() => {
    //     getcreditInvoicedata();
    // }, [creditinvoicerecord])

    const [creditinvoicerecord, setcreditinvoicerecord] = useState({
        invoiceTypeIdentifier: "",
        supplierIdentifier: "",
        customerIdentifier: "",
        statusFlag: 3,
        iscreditnote: true,
        isprofomo: false,
        sortfield: "",
        descflag: false,
        search: "",
        currentPage: 1,
        pageSize: 10,
        customerType: "",
        userIdentifier: CurrentUser
    })

    const getcreditInvoicedata = () => {
        AuthService.PostServiceCall('api/Invoice/GetAllInvoices', creditinvoicerecord)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    if (res.data) {
                        setcreditdata(res.data);
                        setcreditresponsedata(res.recordCount)
                    }
                    else {
                        setcreditdata([]);
                        setcreditresponsedata(0)
                    }
                }
                else {
                    setcreditdata([]);
                    setcreditresponsedata(0)
                }
            })
            .catch((err) => console.log("Error", err));
    }

    const handlecreditAllInvoiceSearch = () => {
        //console.log(creditsearchvalue);
        setcreditinvoicerecord({ ...creditinvoicerecord, search: creditsearchvalue, currentPage: creditPagination.Current_page = 1 })
    }

    useEffect(() => {
        creditinvoicetypedata();
    }, [creditinvoicerecord]);

    const handleviewcreditdata = (identifier) => {
        var input_amount = ''
        //   console.log(identifier);
        setcreditInvoiceIdentifier(identifier)
        AuthService.GetServiceCall(`api/Invoice/GetInvoiceById?InvoiceIdentifier=${identifier}`)
            .then((res) => {
                //console.log(res);
                if (res && res !== '' && res.status === "Success") {
                    // console.log(res);
                    if (res.data) {
                        setcreditviewdata(res.data);
                        setcredtitinvoicetype(res.data.invoiceTypeConfigurationIdentifier)
                        if (res.data.amountInput && res.data.amountInput != undefined && res.data.amountInput != null) {
                            setcreditinvoiceamnt(res.data.amountInput)
                            input_amount = res.data.amountInput
                        } else {
                            let temp = parseFloat(res.data.invoiceRate)
                            setcreditinvoiceamnt(temp)
                            input_amount = parseFloat(res.data.invoiceRate)
                            setcreditviewdata((previousState) => ({
                                ...previousState, amountInput: temp
                            }));

                        }
                        setcreditcalculatetype(res.data.inputType)
                        setcreditsuppliertype(res.data.supplierIdentifier)
                        setcreditsuppcode(res.data.supplierStateCode)
                        setcreditgstflag(res.data.regUnregFlg === true ? 2 : 1)
                        setcredititem(res.data.invoiceItemMasterIdentifier)
                        setcreditcustomercode(res.data.customerStateCode)
                        setcreditsalesamnt(res.data.remarkSalesAmount)
                        setcreditcontractdata(res.data.contractIdentifier)
                        setCreditShow(2);
                        AllCreditCalculation
                            (res.data.inputType, res.data.invoiceTypeConfigurationIdentifier, res.data.contractIdentifier, res.data.invoiceItemMasterIdentifier,
                                res.data.remarkSalesAmount, res.data.supplierStateCode, res.data.customerStateCode, input_amount,identifier
                            )
                    }
                }
            })
    }
    useEffect(() => {
        if (creditinvoicetype) {
            creditinvoicetypedata();
        }
    }, [creditinvoicetype])

    const creditinvoicetypedata = () => {
        if (creditinvoicetype) {
            AuthService.GetServiceCall('api/invoiceTypeConfigurations/GetInvoiceType?InvoiceTypeIdentifiers=' + creditinvoicetype)
                .then((res) => {
                    // console.log(res);
                    if (res && res !== '' && res.responseData) {
                        setcredeitinvoicedata(res.responseData);
                        // AllCreditCalculation();
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }


    const AllCreditCalculation = (inputType, invoiceTypeConfigurationIdentifier, contractIdentifier, invoiceItemMasterIdentifier, remarkSalesAmount, supplierStateCode, customerStateCode, input_amount,identifier) => {
        // console.log('selected_input', inputType)
        var CalcObj = {
            selected_Calculation: Number(inputType),
            invoice_Type_Identifier: invoiceTypeConfigurationIdentifier,
            contract_Identifier: contractIdentifier,
            item_Identifier: invoiceItemMasterIdentifier,
            salesAmountRegUnReg: Number(remarkSalesAmount),
            supplier_StateCode: supplierStateCode,
            customer_StateCode: customerStateCode,
            invoice_Amount: Number(input_amount),
            gstFlag: creditgstflag,
            invoiceIdentifier: identifier
        };
        //   console.log(CalcObj)
        AuthService.PostServiceCall('api/Calculation/GetTaxCalculation', CalcObj).then((res) => {
            // console.log(res);
            if (res.status == "Success") {
                if (res.data) {
                    // console.log(res.data);
                    setcreditcalculatedata(res.data)
                }
            }
        }).catch((err) => console.log("Error:", err));
    }

    const handleApprovecreditInvoice = (identifier, invoicestatus) => {
        //   console.log(identifier, invoicestatus);
        AuthService.GetServiceCall(`api/Invoice/DoUpdateCreditNote?InvoiceIdentifier=${identifier}&InvoiceNoteStatus=${invoicestatus}&UserIdentifier=${CurrentUser}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    document.getElementById('approvedcredit_successfully').click();

                    setCreditShow(1);
                    getcreditInvoicedata();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log("Error:", err));
    }

    const [creditRejectObj, creditsetRejectObj] = useState({
        "invoiceIdentifier": "",
        "reasonforRejection": "",
        "userIdentifier": CurrentUser
    });

    const handleRejectcreditInvoice = (identifier) => {
        var Reject = { ...creditRejectObj, invoiceIdentifier: identifier }

        AuthService.PostServiceCall(`api/Invoice/DoRejectCreditInvoice`, Reject)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    document.getElementById('rejectedcredit_successfully').click();
                    setCreditShow(1);
                    getcreditInvoicedata();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log("Error:", err));
    }

    const handleDownloadCreditData = (identifier, creditnote) => {

        AuthService.GetServiceCall(`api/Excel/DownloadPdfInvoice?identifier=${identifier}&invoiceNoteStatus=${creditnote}`)
            .then((res) => {

                //   console.log(res);
                if (res && res !== '' && res.responseMessage == "success") {
                    const href = `data:application/pdf;base64,${res.basestring}`;
                    //   console.log(href);
                    // Create a download link and trigger a click event
                    const link = document.createElement('a');
                    link.href = href;
                    link.download = res.filename; // Set the desired file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                }
            })
    }

    // All Creadit Dharshan Ends
    // CreditNote Pending Ends

    const [pendingcreditshow, setpendingCreditShow] = useState(1);
    const [pendingcreditdata, setpendingcreditdata] = useState();
    const [pendingcreditresponsedata, setpendingcreditresponsedata] = useState();
    const [pendingcreditviewdata, setpendingcreditviewdata] = useState();
    const [pendingcreditPagination, setpendingCreditPagination] = useState({ Current_page: 1 });
    const [pendingcreditsearch, setpendingcreditsearch] = useState("");
    const [pendingcreditresponse, pendingsetcreditresponse] = useState();
    const [pendingcreditcalculatetype, setpendingcreditcalculatetype] = useState();
    const [pendingcreditinvoicetype, setpendingcredtitinvoicetype] = useState('');
    const [pendingcreditsuppliertype, setpendingcreditsuppliertype] = useState('');
    const [pendingcreditinvoiceamnt, setpendingcreditinvoiceamnt] = useState();
    const [pendingcreditsuppcode, setpendingcreditsuppcode] = useState('');
    const [pendingcreditgstflag, setpendingcreditgstflag] = useState();
    const [pendingcredititem, setpendingcredititem] = useState('');
    const [pendingcreditcustomercode, setpendingcreditcustomercode] = useState('');
    const [pendingcreditsalesamnt, setpendingcreditsalesamnt] = useState();
    const [pendingcreditcalculatedata, setpendingcreditcalculatedata] = useState();
    const [pendingcreditcontractdata, setpendingcreditcontractdata] = useState()
    const [pendingcreditinvoicedata, setpendingcredeitinvoicedata] = useState();


    const pendingcreditpagenumbers = [];
    for (let i = 1; i <= Math.ceil(pendingcreditresponse && pendingcreditresponse / 10); i++) {
        pendingcreditpagenumbers.push(i);
    }

    const pendingcredithandlePageChange = (pageNumber) => {
        setpendingCreditPagination({ ...pendingcreditPagination, Current_page: pendingcreditPagination.Current_page = pageNumber });
        setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, currentPage: pendingcreditPagination.Current_page })
    };

    const handlependingcreditsearchdata = () => {
        setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, search: pendingcreditsearch, currentPage: pendingcreditinvoicerecord.currentPage = 1 })
    }

    const [pendingcreditinvoicerecord, setpendingsetcreditinvoicerecord] = useState({
        invoiceTypeIdentifier: "",
        supplierIdentifier: "",
        customerIdentifier: "",
        statusFlag: 0,
        iscreditnote: true,
        isprofomo: false,
        sortfield: "",
        descflag: false,
        search: "",
        currentPage: 1,
        pageSize: 10,
        customerType: "",
        userIdentifier: CurrentUser
    })

    const getPendingCreditInvoiceData = () => {
        AuthService.PostServiceCall('api/Invoice/GetAllInvoices', pendingcreditinvoicerecord)
            .then((res) => {
                // console.log(res);
                if (res && res !== '' && res.status === "Success" && res.data) {
                    if (res.data) {
                        setpendingcreditviewdata(res.data);
                    }
                    pendingsetcreditresponse(res.recordCount);
                }
            })
    }

    useEffect(() => {
        if (CurrentUser)
            getPendingCreditInvoiceData();
    }, [pendingcreditinvoicerecord])

    const handlependingcreditviewdata = (identifier) => {
        var input_amount = ''
        setcreditInvoiceIdentifier(identifier)
        AuthService.GetServiceCall(`api/Invoice/GetInvoiceById?InvoiceIdentifier=${identifier}`)
            .then((res) => {
                // console.log(res);
                if (res && res !== '' && res.status === "Success") {
                    if (res.data) {

                        setpendingcreditdata(res.data);
                        setpendingcredtitinvoicetype(res.data.invoiceTypeConfigurationIdentifier)

                        if (res.data.amountInput && res.data.amountInput != undefined && res.data.amountInput != null) {
                            setpendingcreditinvoiceamnt(res.data.amountInput)
                            input_amount = res.data.amountInput
                        } else {
                            let temp = parseFloat(res.data.invoiceRate)
                            setpendingcreditinvoiceamnt(temp)
                            input_amount = parseFloat(res.data.invoiceRate)
                            setpendingcreditdata((previousState) => ({
                                ...previousState, amountInput: temp
                            }));

                        }

                        setpendingcreditcalculatetype(res.data.inputType)
                        setpendingcreditsuppliertype(res.data.supplierIdentifier)
                        setpendingcreditsuppcode(res.data.supplierStateCode)
                        setpendingcreditgstflag(res.data.regUnregFlg === true ? 2 : 1)
                        setpendingcredititem(res.data.invoiceItemMasterIdentifier)
                        setpendingcreditcustomercode(res.data.customerStateCode)
                        setpendingcreditsalesamnt(res.data.remarkSalesAmount)
                        setpendingcreditcontractdata(res.data.contractIdentifier)
                        setpendingCreditShow(2);
                        pendingCreditCalculation
                            (
                                res.data.inputType, res.data.invoiceTypeConfigurationIdentifier, res.data.contractIdentifier, res.data.invoiceItemMasterIdentifier,
                                res.data.remarkSalesAmount, res.data.supplierStateCode, res.data.customerStateCode, input_amount,identifier
                            )
                    }

                }
            })
    }
    const pendingcreditinvoicetypedata = () => {
        if (pendingcreditinvoicetype) {
            AuthService.GetServiceCall('api/invoiceTypeConfigurations/GetInvoiceType?InvoiceTypeIdentifiers=' + pendingcreditinvoicetype)
                .then((res) => {
                    //   console.log(res);
                    if (res && res !== '' && res.responseData) {
                        setpendingcredeitinvoicedata(res.responseData);
                        // pendingCreditCalculation();
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }

    useEffect(() => {
        pendingcreditinvoicetypedata();
    }, [pendingcreditinvoicetype]);

    const pendingCreditCalculation = (inputType, invoiceTypeConfigurationIdentifier, contractIdentifier, invoiceItemMasterIdentifier, remarkSalesAmount, supplierStateCode, customerStateCode, input_amount,identifier) => {
        var CalcObj = {
            selected_Calculation: Number(inputType),
            invoice_Type_Identifier: invoiceTypeConfigurationIdentifier,
            contract_Identifier: contractIdentifier,
            item_Identifier: invoiceItemMasterIdentifier,
            salesAmountRegUnReg: Number(remarkSalesAmount),
            supplier_StateCode: supplierStateCode,
            customer_StateCode: customerStateCode,
            invoice_Amount: Number(input_amount),
            gstFlag: pendingcreditgstflag,
            invoiceIdentifier: identifier
        };
        //   console.log(CalcObj)
        AuthService.PostServiceCall('api/Calculation/GetTaxCalculation', CalcObj).then((res) => {
            if (res && res !== '' && res.status === "Success") {
                if (res.data)
                    setpendingcreditcalculatedata(res.data)
            }
        }).catch((err) => console.log("Error:", err));
    }
    const handleApprovependingcreditInvoice = (identifier, invoicestatus) => {


        AuthService.GetServiceCall(`api/Invoice/DoUpdateCreditNote?InvoiceIdentifier=${identifier}&InvoiceNoteStatus=${invoicestatus}&UserIdentifier=${CurrentUser}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    document.getElementById('pendingapprovedcredit_successfully').click();
                    setpendingCreditShow(1);
                    getPendingCreditInvoiceData();
                }
                else {

                }
            }).catch((err) => console.log("Error:", err));
    }

    const [pendingcreditRejectObj, pendingcreditsetRejectObj] = useState({
        "invoiceIdentifier": "",
        "reasonforRejection": "",
        "userIdentifier": CurrentUser
    });

    const handleRejectpendingcreditInvoice = (identifier) => {
        var Reject = { ...pendingcreditRejectObj, invoiceIdentifier: identifier }

        AuthService.PostServiceCall(`api/Invoice/DoRejectCreditInvoice`, Reject)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    document.getElementById('pendingrejectedcredit_successfully').click();
                    setpendingCreditShow(1);
                    getPendingCreditInvoiceData();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log("Error:", err));
    }

    // CreditNote Pending Ends

    // dharshan credit approved starts
    const [approvalcreditshow, setapprovalcreditshow] = useState(1);
    const [CheckMark, setCheckMark] = useState([]);
    const [approvalcreditdata, setapprovalcreditdata] = useState();
    const [approvalcreditresponsedata, setapprovalcreditresponsedata] = useState();
    const [approvalcreditviewdata, setapprovalcreditviewdata] = useState();
    const [approvalcreditPagination, setapprovalCreditPagination] = useState({ Current_page: 1 });
    const [approvalcreditsearch, setapprovalcreditsearch] = useState("");
    const [approvalcreditresponse, setapprovalcreditresponse] = useState();
    const [approvalcreditcalculatetype, setapprovalcreditcalculatetype] = useState();
    const [approvalcreditinvoicetype, setapprovalcredtitinvoicetype] = useState('');
    const [approvalcreditsuppliertype, setapprovalcreditsuppliertype] = useState('');
    const [approvalcreditinvoiceamnt, setapprovalcreditinvoiceamnt] = useState();
    const [approvalcreditsuppcode, setapprovalcreditsuppcode] = useState('');
    const [approvalcreditgstflag, setapprovalcreditgstflag] = useState();
    const [approvalcredititem, setapprovalcredititem] = useState('');
    const [approvalcreditcustomercode, setapprovalcreditcustomercode] = useState('');
    const [approvalcreditsalesamnt, setapprovalcreditsalesamnt] = useState();
    const [approvalcreditcalculatedata, setapprovalcreditcalculatedata] = useState();
    const [approvalcreditcontractdata, setapprovalcreditcontractdata] = useState()
    const [approvalcreditinvoicedata, setapprovalcredeitinvoicedata] = useState();



    const approvalcreditpagenumbers = [];
    for (let i = 1; i <= Math.ceil(approvalcreditresponse && approvalcreditresponse / 10); i++) {
        approvalcreditpagenumbers.push(i);
    }
    const approvalcredithandlePageChange = (pageNumber) => {
        setAllCheckMark(false)
        setCheckMark((prevMailCheckMark) =>
            prevMailCheckMark.filter((id) => false)
        );
        setapprovalCreditPagination({ ...approvalcreditPagination, Current_page: approvalcreditPagination.Current_page = pageNumber });
        setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, currentPage: approvalcreditPagination.Current_page })
    };


    const handleapprovalsearch = () => {
        setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, search: approvalcreditsearch, currentPage: approvalcreditPagination.Current_page = 1 })
    }

    const handleSendmailapprovalcredit = () => {
        //   console.log(CheckMark);
        if (CheckMark.length > 0) {
            sendMailMethod(CheckMark)
        }
    }

    const sendMailMethod = (Identifiers) => {
        AuthService.PostServiceCall('api/SendMail/SendEmail', Identifiers)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    setCheckMark((prevMailCheckMark) =>
                        prevMailCheckMark.filter((id) => false)
                    );
                }
                else {
                    setCheckMark((prevMailCheckMark) =>
                        prevMailCheckMark.filter((id) => false)
                    );
                }
            })
    }

    // useEffect(() => {
    //     handleSendmailapprovalcredit();
    // }, [CheckMark]);

    const handlemailCheckMark = (identifier) => {
        sendMailMethod([identifier])
    };

    const [approvalcreditinvoicerecord, setapprovalcreditinvoicerecord] = useState({
        invoiceTypeIdentifier: "",
        supplierIdentifier: "",
        customerIdentifier: "",
        statusFlag: 1,
        iscreditnote: true,
        isprofomo: false,
        sortfield: "",
        descflag: false,
        search: "",
        currentPage: 1,
        pageSize: 10,
        customerType: "",
        userIdentifier: CurrentUser
    })

    const getApprovalcreditViewData = () => {
        AuthService.PostServiceCall('api/Invoice/GetAllInvoices', approvalcreditinvoicerecord)
            .then((res) => {
                //console.log(res);
                if (res && res !== '' && res.status === "Success") {
                    if (res.data) {
                        setapprovalcreditdata(res.data);
                    }
                    setapprovalcreditresponse(res.recordCount);
                }
            })
    }

    useEffect(() => {
        if (CurrentUser)
            getApprovalcreditViewData();
    }, [approvalcreditPagination])

    const handleapprovalcreditviewdata = (identifier) => {
        var input_amount = ''
        // setapprovalcreditshow(2);
        setcreditInvoiceIdentifier(identifier)
        AuthService.GetServiceCall(`api/Invoice/GetInvoiceById?InvoiceIdentifier=${identifier}`)
            .then((res) => {
                //   console.log(res);
                if (res && res !== '' && res.status === "Success") {
                    if (res.data) {
                        setapprovalcreditviewdata(res.data);
                        setapprovalcredtitinvoicetype(res.data.invoiceTypeConfigurationIdentifier)

                        if (res.data.amountInput && res.data.amountInput != undefined && res.data.amountInput != null) {
                            setapprovalcreditinvoiceamnt(res.data.amountInput)
                            input_amount = res.data.amountInput
                        } else {
                            let temp = parseFloat(res.data.invoiceRate)
                            setapprovalcreditinvoiceamnt(temp)
                            input_amount = parseFloat(res.data.invoiceRate)
                            setapprovalcreditviewdata((previousState) => ({
                                ...previousState, amountInput: temp
                            }));

                        }

                        setapprovalcreditcalculatetype(res.data.inputType)
                        setapprovalcreditsuppliertype(res.data.supplierIdentifier)
                        setapprovalcreditsuppcode(res.data.supplierStateCode)
                        setapprovalcreditgstflag(res.data.regUnregFlg === true ? 2 : 1)
                        setapprovalcredititem(res.data.invoiceItemMasterIdentifier)
                        setapprovalcreditcustomercode(res.data.customerStateCode)
                        setapprovalcreditsalesamnt(res.data.remarkSalesAmount)
                        setapprovalcreditcontractdata(res.data.contractIdentifier)
                        setapprovalcreditshow(2);
                        approvalCreditCalculation(res.data.inputType, res.data.invoiceTypeConfigurationIdentifier, res.data.contractIdentifier, res.data.invoiceItemMasterIdentifier,
                            res.data.remarkSalesAmount, res.data.supplierStateCode, res.data.customerStateCode, input_amount,identifier)
                    }
                }
            })
    }

    const approvalcreditinvoicetypedata = () => {
        if (approvalcreditinvoicetype) {
            AuthService.GetServiceCall('api/invoiceTypeConfigurations/GetInvoiceType?InvoiceTypeIdentifiers=' + approvalcreditinvoicetype)
                .then((res) => {
                    setapprovalcredeitinvoicedata(res.responseData);
                }).catch((err) => console.log("Error:", err));
        }
    }

    useEffect(() => {
        approvalcreditinvoicetypedata();
    }, [approvalcreditinvoicetype]);

    const approvalCreditCalculation = (inputType, invoiceTypeConfigurationIdentifier, contractIdentifier, invoiceItemMasterIdentifier, remarkSalesAmount, supplierStateCode, customerStateCode, input_amount,identifier) => {
        var CalcObj = {
            selected_Calculation: Number(inputType),
            invoice_Type_Identifier: invoiceTypeConfigurationIdentifier,
            contract_Identifier: contractIdentifier,
            item_Identifier: invoiceItemMasterIdentifier,
            salesAmountRegUnReg: Number(remarkSalesAmount),
            supplier_StateCode: supplierStateCode,
            customer_StateCode: customerStateCode,
            invoice_Amount: Number(input_amount),
            gstFlag: approvalcreditgstflag,
            invoiceIdentifier: identifier
        };
        //   console.log(CalcObj)
        AuthService.PostServiceCall('api/Calculation/GetTaxCalculation', CalcObj).then((res) => {
            if (res && res !== '' && res.status === "Success") {
                //console.log(res)
                setapprovalcreditcalculatedata(res.data)
            }
        }).catch((err) => console.log("Error:", err));
    }
    // dharshan credit approved ends 

    //State_code_configuration_start

    const [stateconfig, setstateconfig] = useState(1)
    const [Stateconfigdata, setstateconfigfdata] = useState()
    const [statesearch, setstatesearch] = useState('')
    const [staterespone, setstateresponse] = useState()


    const GetStateconfigdata = () => {
        const actualColumnName = columnName !== undefined ? columnName : 'StateCodeAutoId';
        AuthService.GetServiceCall(`api/StateCodes/StateCode?page=${StatePaginations.Current_page}&pageSize=${10}&sortField=${actualColumnName}&isAscending=${Ascending}&searchTerm=${statesearch}`)
            .then((res) => {
                if (res && res !== '' && res.responseData) {
                    setstateconfigfdata(res.responseData)
                    setstateresponse(res.responseCount)
                }
            })
    }
    // const handle_config_search = (e) => {
    //     setstatesearch(e.target.value)
    //     if (e.target.value === '') {
    //         GetStateconfigdata();
    //     }
    // }
    const [StatePaginations, SetStatePaginations] = useState({ Current_page: 1 });


    // useEffect(() => {
    //     if (CurrentUser)
    //         GetStateconfigdata();
    // }, [StatePaginations, Ascending])


    const StatepageNumbers = [];
    for (let i = 1; i <= Math.ceil(staterespone && staterespone / 10); i++) {
        StatepageNumbers.push(i);
    }
    const StatehandlePageChange = (pageNumber) => {
        SetStatePaginations({ ...StatePaginations, Current_page: StatePaginations.Current_page = pageNumber });
    };


    const [statenamecode, setstatenamecode] = useState({
        "stateName": "",
        "stateCode": "",
        "stateIdentifier": "",
        "userMasterIdentifier": CurrentUser
    })

    const [stateNameError, setStateNameError] = useState('');
    const [stateCodeError, setStateCodeError] = useState('');
    const [statecodevald, setstatecodevald] = useState(true);

    const handlestatenamecodechange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setstatenamecode({ ...statenamecode, [name]: value })
    }

    const handlesubmitstate = () => {
        if (statenamecode.stateName.length > 100 || statenamecode.stateName === "") {
            setstatecodevald(false);
        }
        if (statenamecode.stateCode.length > 100 || statenamecode.stateCode === "") {
            setstatecodevald(false);
        }
        else {
            AuthService.PostServiceCall('api/StateCodes/StateCodeInsert', statenamecode)

                .then((res) => {

                    if (res && res !== '' && res.responseMessage === 'StateName already exists.') {
                        document.getElementById('model_triger_tw').click();
                    } else {
                        setstatecodevald(true);
                        setstateconfig(1)
                        GetStateconfigdata();
                        document.getElementById('model_triger_three').click();

                        // setstatenamecode({ ...statenamecode, stateName: '', stateCode: '' });
                    }
                })
        }
    }

    //State_code_configuration_End


    //Units of Measurement Starts

    const [unitsshow, setunitshow] = useState(1)
    const [unitdata, setunitdata] = useState()
    const [unitresponse, setunitresponse] = useState()
    const [unitsearch, setunitsearch] = useState('')
    const [unitPaginations, SetunitPaginations] = useState({ Current_page: 1 });
    const [unitmeasurevald, setunitmeasurevald] = useState(true);
    const Getunitdata = () => {
        const actualColumnName = columnName !== undefined ? columnName : 'UnitMeasurementConfigurationAutoId';

        AuthService.GetServiceCall(`api/UnitMeasurement/GetAllUnitMeasurement?page=${unitPaginations.Current_page}&pageSize=${10}&sortField=${actualColumnName}&isAscending=${Ascending}&searchTerm=${unitsearch}`)
            .then((res) => {
                if (res && res !== '' && res.responseData) {
                    setunitdata(res.responseData)
                    setunitresponse(res.responseCount)
                }
            })
    }

    // useEffect(() => {
    //     Getunitdata();
    // }, [unitPaginations, Ascending])

    const unitpageNumbers = [];
    for (let i = 1; i <= Math.ceil(unitresponse && unitresponse / 10); i++) {
        unitpageNumbers.push(i);
    }
    const unithandlePageChange = (pageNumber) => {
        SetunitPaginations({ ...unitPaginations, Current_page: unitPaginations.Current_page = pageNumber });
    };

    const [unitinput, setunitinpt] = useState({ unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '', userMasterIdentifier: CurrentUser })
    const [unitviewdata, setunitviewdata] = useState({ unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '', userMasterIdentifier: CurrentUser })
    const handleunitchanges = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setunitinpt({ ...unitinput, [name]: value })
    }
    const handleunitinsertsave = () => {
        if (unitinput.unitMeasurementName === "" || unitinput.unitMeasurementName.length > 255) {
            setunitmeasurevald(false);
        }
        if (unitinput.unitMeasurementSymbol === "" || unitinput.unitMeasurementSymbol.length > 255) {
            setunitmeasurevald(false);
        }
        else {
            AuthService.PostServiceCall('api/UnitMeasurement/UnitMeasurementInsert', unitinput)
                .then((res) => {
                    if (res && res !== '' && res.responseMessage !== 'Failed') {
                        setunitshow(1)
                        Getunitdata();
                        setunitmeasurevald(true);
                        document.getElementById('user_master_model_1').click();
                        setunitinpt({ ...unitinput, unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });
                    }
                })
        }
    }

    const [unitactiveflag, setunitactiveflag] = useState(false)

    const uniteditapi = (uqid) => {
        AuthService.GetServiceCall(`api/UnitMeasurement/GetUnitConfiguration?UnitConfigIdentifier=${uqid}`)
            .then((res) => {
                //   console.log(res)
                if (res && res !== '' && res.responseData && res.responseData[0]) {
                    setunitactiveflag(res.responseData[0].activeFlag)
                    setunitviewdata({
                        ...unitviewdata, unitMeasurementName: res.responseData[0].unitMeasurementName,
                        unitMeasurementSymbol: res.responseData[0].unitMeasurementSymbol,
                        unitMeasurementConfigurationIdentifier: res.responseData[0].unitMeasurementConfigurationIdentifier
                    })
                }
            })
    }

    const handleunitinputedit = (uqid) => {
        setunitshow(3)
        uniteditapi(uqid)
    }

    useEffect(() => {
        setunitinpt({
            ...unitinput,
            unitMeasurementName: unitviewdata.unitMeasurementName,
            unitMeasurementSymbol: unitviewdata.unitMeasurementSymbol,
            unitMeasurementConfigurationIdentifier: unitviewdata.unitMeasurementConfigurationIdentifier
        })
    }, [unitviewdata])

    const handleeditunitinsertsave = () => {
        setunitinpt({ ...unitinput, unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });

        AuthService.PostServiceCall('api/UnitMeasurement/UnitMeasurementUpdate', unitinput)
            .then((res) => {
                //   console.log(res)
                setunitshow(1)
                Getunitdata();
                setunitinpt({ ...unitinput, unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });
                document.getElementById('user_master_model_1').click();
            })
    }
    const Actionbutton = {
        delete: 'Delete',
        enable: 'Enable',
        disable: 'Disable'
    }
    const UnitDelete = () => {
        AuthService.PostServiceCall(`api/UnitMeasurement/ManageMeasurement?UnitMeasurementConfigurationIdentifier=${unitviewdata.unitMeasurementConfigurationIdentifier}&action=0`)
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.responseMessage === "Success") {
                    setunitshow(1)
                    Getunitdata();
                    document.getElementById('user_master_model_2').click();
                } else if (res && res !== '' && res.responseMessage === "Unit Measurement is already used, cannot delete") {
                    document.getElementById('user_master_already_exist').click();
                }
            })
    }
    // const [unitdisable, setunitdisable] = useState(true)

    const unitenabled = () => {
        AuthService.PostServiceCall(`api/UnitMeasurement/ManageMeasurement?UnitMeasurementConfigurationIdentifier=${unitviewdata.unitMeasurementConfigurationIdentifier}&action=${Actionbutton.enable}`)
            .then((res) => {

                // setunitdisable(false)

                document.getElementById('user_master_enable_success').click();
                uniteditapi(unitviewdata.unitMeasurementConfigurationIdentifier)
            })
    }
    const unitdisabled = () => {
        AuthService.PostServiceCall(`api/UnitMeasurement/ManageMeasurement?UnitMeasurementConfigurationIdentifier=${unitviewdata.unitMeasurementConfigurationIdentifier}&action=${Actionbutton.disable}`)
            .then((res) => {

                document.getElementById('user_master_disable').click();
                // setunitdisable(true)

                uniteditapi(unitviewdata.unitMeasurementConfigurationIdentifier)
            })
    }

    //Units of Measurement Ends


    //Items master configuration starts

    const [invoicetypeeditview, setinvoicetypeeditview] = useState({ Edit: true, enable: false })
    const [itemshow, setitemshow] = useState(1)
    const [itemdata, setitemdata] = useState()
    const [itemresponse, setitemresponse] = useState()
    const [itemsearch, setitemsearch] = useState('')
    const [itemdropdown, setitemdropdown] = useState()
    const [itemPaginations, SetitemPaginations] = useState({ Current_page: 1 });
    const [itemdropdownvalue, setitemdropdownvalue] = useState('')

    const [itemenbled, setitemenabled] = useState(null)
    const [itemenabledvalue, setitemenabledvalue] = useState('')

    const [itemdropdowncreate, setitemdropdowncreate] = useState()
    const [itemdropdownvaluecreate, setitemdropdownvaluecreate] = useState('')
    const [errfieldone, seterrfieldone] = useState(true)
    const itemcreateObj = {
        itemName: '',
        itemCode: '',
        itemDescription: '',
        userMasterIdentifier: CurrentUser,
        hsncode1: '',
        hsncode2: '',
        hsncode3: '',
        hsncode: '',
        cgstrate: '',
        sgstrate: '',
        igstrate: '',
        ccessRate: '',
        sapgl: '',
        itemMasterIdentifier: '',
        invoiceTypeIdentifier: ''
    }
    const [createitemmaster, setcreateitemmaster] = useState(itemcreateObj)
    const Getitemdata = () => {
        const actualColumnName = columnName !== undefined ? columnName : 'InvoiceItemMasterAutoId';


        let apiUrl = `api/ItemMasters/GetAllItemMaster?page=${itemPaginations.Current_page}&pageSize=${10}&sortField=${actualColumnName}&isAscending=${Ascending}
              &searchTerm=${itemsearch}&InvoiceType=${itemdropdownvalue}`;

        if (itemenbled !== null) {
            apiUrl += `&activeflag=${itemenbled}`;
        }

        AuthService.GetServiceCall(apiUrl)
            .then((res) => {
                if (res && res !== '' && res.responseData)
                    setitemdata(res.responseData);
                setitemresponse(res.responseCount);
            });
    };
    const itempageNumbers = [];
    for (let i = 1; i <= Math.ceil(itemresponse && itemresponse / 10); i++) {
        itempageNumbers.push(i);
    }
    const itemhandlePageChange = (pageNumber) => {
        SetitemPaginations({ ...itemPaginations, Current_page: itemPaginations.Current_page = pageNumber });
    };

    const Getinvoicetype = () => {
        AuthService.GetServiceCall(`api/invoiceTypeConfigurations/GetAllInvoiceType?page=${0}&pageSize=${0}&sortField=${'UnitMeasurementName'}&isAscending=${false}&userIdentifier=${CurrentUser}`)

            .then((res) => {
                // console.log('checking', res)
                if (res && res !== '' && res.responseData) {
                    setitemdropdown(res.responseData)
                    // console.log('typedata', res.responseData)
                    setitemdropdowncreate(res.responseData)
                }
            })
    }

    // useEffect(() => {
    //     if (CurrentUser) {
    //         Getitemdata();
    //         Getinvoicetype();
    //     }
    // }, [itemPaginations, itemdropdownvalue, itemenabledvalue, Ascending])

    const handleitemDropdownChange = (event) => {
        setitemenabledvalue(event.target.value)

        if (event.target.value === "1") {
            setitemenabled(true);
        } else if (event.target.value === "2") {
            setitemenabled(false);
        } else {
            setitemenabled(null);
        }
    };

    const handleitemcreatechange = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        setcreateitemmaster({ ...createitemmaster, [name]: value })
        if (name === 'hsncode') {
            const newValue = e.target.value.replace(/\D/g, '');
            const truncatedValue = newValue.slice(0, 8);
            setcreateitemmaster({ ...createitemmaster, hsncode: truncatedValue })
        } else if (name === 'hsncode1') {
            const newValue_1 = e.target.value.replace(/\D/g, '');
            const truncatedValue_1 = newValue_1.slice(0, 8);
            setcreateitemmaster({ ...createitemmaster, hsncode1: truncatedValue_1 })
        } else if (name === 'hsncode2') {
            const newValue_2 = e.target.value.replace(/\D/g, '');
            const truncatedValue_2 = newValue_2.slice(0, 8);
            setcreateitemmaster({ ...createitemmaster, hsncode2: truncatedValue_2 })
        } else if (name === 'hsncode3') {
            const newValue_3 = e.target.value.replace(/\D/g, '');
            const truncatedValue_3 = newValue_3.slice(0, 8);
            setcreateitemmaster({ ...createitemmaster, hsncode3: truncatedValue_3 })
        } else if (name === 'cgstrate') {
            var decimalRegex = /^([0-9]|[0-9]\d|50)(\.\d{0,2})?$/;
            if (decimalRegex.test(e.target.value)) {
                setcreateitemmaster({ ...createitemmaster, cgstrate: e.target.value })
            }
            else {
                if (e.target.value == 0) {
                    setcreateitemmaster({ ...createitemmaster, cgstrate: '' })
                }
                else {
                    setcreateitemmaster({ ...createitemmaster })
                }
            }
        } else if (name === 'sgstrate') {
            var decimalRegex = /^([0-9]|[0-9]\d|50)(\.\d{0,2})?$/;
            if (decimalRegex.test(e.target.value)) {
                setcreateitemmaster({ ...createitemmaster, sgstrate: e.target.value })
            }
            else {
                if (e.target.value == 0) {
                    setcreateitemmaster({ ...createitemmaster, sgstrate: '' })
                }
                else {
                    setcreateitemmaster({ ...createitemmaster })
                }
            }
        } else if (name === 'igstrate') {
            var decimalRegex = /^([0-9]|[0-9]\d|50)(\.\d{0,2})?$/;
            if (decimalRegex.test(e.target.value)) {
                setcreateitemmaster({ ...createitemmaster, igstrate: e.target.value })
            }
            else {
                if (e.target.value == 0) {
                    setcreateitemmaster({ ...createitemmaster, igstrate: '' })
                }
                else {
                    setcreateitemmaster({ ...createitemmaster })
                }
            }
        } else if (name === 'ccessRate') {
            var decimalRegex = /^([0-9]|[0-9]\d|50)(\.\d{0,2})?$/;
            if (decimalRegex.test(e.target.value)) {
                setcreateitemmaster({ ...createitemmaster, ccessRate: e.target.value })
            }
            else {
                if (e.target.value == 0) {
                    setcreateitemmaster({ ...createitemmaster, ccessRate: '' })
                }
                else {
                    setcreateitemmaster({ ...createitemmaster })
                }
            }
        }
    }
    const handleitemsaveinput = () => {
        seterrfieldone(true)
        if (createitemmaster.itemName === '' || createitemmaster.itemCode === '' || createitemmaster.itemDescription === '' || createitemmaster.hsncode1 === ''
            || createitemmaster.hsncode2 === '' || createitemmaster.hsncode3 === '' || createitemmaster.cgstrate === ''
            || createitemmaster.sgstrate === '' || createitemmaster.igstrate === '' || createitemmaster.ccessRate === '' || createitemmaster.invoiceTypeIdentifier === ''
        ) {
            seterrfieldone(false)
        } else {
            AuthService.PostServiceCall('api/ItemMasters/InsetItemMaster', createitemmaster)
                .then((res) => {
                    // console.log(res)
                    if (res && res !== '' && res.responseMessage === "Success") {
                        seterrfieldone(true)
                        setitemshow(1)
                        Getitemdata();
                        document.getElementById('model_triger_item_master').click();
                    } else {

                    }

                })
        }
    }


    const handleitemsaveinputone = () => {
        seterrfieldone(true)
        if (createitemmaster.itemName === '' || createitemmaster.itemCode === '' || createitemmaster.itemDescription === '' || createitemmaster.hsncode === '' || createitemmaster.cgstrate === ''
            || createitemmaster.sgstrate === '' || createitemmaster.igstrate === '' || createitemmaster.ccessRate === '' || createitemmaster.invoiceTypeIdentifier === ''
        ) {
            seterrfieldone(false)
        } else {
            AuthService.PostServiceCall('api/ItemMasters/InsetItemMaster', createitemmaster)
                .then((res) => {
                    // console.log(res)
                    if (res && res !== '' && res.responseMessage === "Success") {
                        seterrfieldone(true)
                        setitemshow(1)
                        Getitemdata();
                        document.getElementById('model_triger_item_master').click();
                    } else {

                    }

                })
        }
    }


    const [itemviewdata, setitemviewdata] = useState(itemcreateObj)
    const [itemeditflag, setitemeditflag] = useState(false)

    const itemeditapi = (unq) => {

        {
            AuthService.GetServiceCall(`api/ItemMasters/GetItemMaster?ItemMasterIdentifier=${unq}`)
                .then((res) => {
                    // console.log(res)
                    if (res && res !== '' && res.responseData) {
                        setitemeditflag(res.responseData[0].activeFlag)
                        setitemviewdata({
                            ...itemviewdata,
                            itemName: res.responseData[0].itemName,
                            itemCode: res.responseData[0].itemCode,
                            // invoiceType: res.responseData[0].invoiceType,
                            itemDescription: res.responseData[0].itemDescription,
                            hsncode: res.responseData[0].hsncode,
                            hsncode1: res.responseData[0].hsncode1,
                            hsncode2: res.responseData[0].hsncode2,
                            hsncode3: res.responseData[0].hsncode3,
                            cgstrate: res.responseData[0].cgstrate,
                            sgstrate: res.responseData[0].sgstrate,
                            igstrate: res.responseData[0].igstrate,
                            ccessRate: res.responseData[0].ccessRate,
                            sapgl: res.responseData[0].sapgl,
                            itemMasterIdentifier: res.responseData[0].itemMasterIdentifier,
                            invoiceTypeIdentifier: res.responseData[0].invoiceTypeIdentifier,
                        })
                    }
                })
        }

    }

    const handleedititem = (unq) => {
        setitemeditview({ ...itemeditview, Edit: true })
        setitemshow(3)
        itemeditapi(unq)
    }

    useEffect(() => {
        if (CurrentUser)
            setcreateitemmaster({
                ...createitemmaster,
                itemName: itemviewdata.itemName,
                itemCode: itemviewdata.itemCode,
                // invoiceType: itemviewdata.invoiceType,
                itemDescription: itemviewdata.itemDescription,
                hsncode: itemviewdata.hsncode,
                hsncode1: itemviewdata.hsncode1,
                hsncode2: itemviewdata.hsncode2,
                hsncode3: itemviewdata.hsncode3,
                cgstrate: itemviewdata.cgstrate,
                sgstrate: itemviewdata.sgstrate,
                igstrate: itemviewdata.igstrate,
                ccessRate: itemviewdata.ccessRate,
                sapgl: itemviewdata.sapgl,
                itemMasterIdentifier: itemviewdata.itemMasterIdentifier,
                invoiceTypeIdentifier: itemviewdata.invoiceTypeIdentifier
            })
    }, [itemviewdata])
    const [ItemRegFlag, setItemRegFlag] = useState(false)


    const handleitemeditsaveinput = () => {
        seterrfieldone(true)
        if (createitemmaster.itemName === '' || createitemmaster.itemCode === '' || createitemmaster.itemDescription === '' || createitemmaster.hsncode1 === '' || createitemmaster.hsncode1 === null
            || createitemmaster.hsncode2 === '' || createitemmaster.hsncode2 === null || createitemmaster.hsncode3 === '' || createitemmaster.hsncode3 === null || createitemmaster.cgstrate === ''
            || createitemmaster.sgstrate === '' || createitemmaster.igstrate === '' || createitemmaster.ccessRate === '' || createitemmaster.invoiceTypeIdentifier === '') {

            seterrfieldone(false)
        }
        else {
            handleItemUpdateSubmit();
            // console.log('four')
        }
    }

    const handleEditInserttwo = () => {
        seterrfieldone(true)
        if (createitemmaster.itemName === '' || createitemmaster.itemCode === '' || createitemmaster.itemDescription === '' || createitemmaster.hsncode === '' || createitemmaster.cgstrate === ''
            || createitemmaster.sgstrate === '' || createitemmaster.igstrate === '' || createitemmaster.ccessRate === '' || createitemmaster.invoiceTypeIdentifier === '') {
            seterrfieldone(false)
        }
        else {
            handleItemUpdateSubmit();
            // console.log('four')
        }
    }

    const handleItemUpdateSubmit = () => {
        AuthService.PostServiceCall('api/ItemMasters/UpdateItemMaster', createitemmaster)
            .then((res) => {

                seterrfieldone(true)
                setitemshow(1)
                Getitemdata();
                document.getElementById('model_triger_item_master').click();
            })
    }


    const handledeleteitemmaster = () => {
        AuthService.PostServiceCall(`api/ItemMasters/ManageInvoice?ItemMasterIdentifier=${itemviewdata.itemMasterIdentifier}&action=0`)
            .then((res) => {

                if (res && res !== '' && res.responseMessage === "Success") {
                    setitemshow(1)
                    Getitemdata();
                    setcreateitemmaster(itemcreateObj)
                    document.getElementById('model_triger_item_delete').click();
                } else if (res && res !== '' && res.responseMessage === "Invoice type is already used, cannot delete") {
                    document.getElementById('invoice_typ_exist').click();
                }

            })
    }
    const [itemeditview, setitemeditview] = useState({ Edit: true, enable: false })

    const Itemmasterenabled = () => {

        AuthService.PostServiceCall(`api/ItemMasters/ManageInvoice?ItemMasterIdentifier=${itemviewdata.itemMasterIdentifier}&action=${Actionbutton.enable}`)
            .then((res) => {
                // console.log(res)
                itemeditapi(itemviewdata.itemMasterIdentifier)
                if (res && res !== '' && res.responseMessage === "Success") {
                    document.getElementById('model_one_enable_success').click();
                    Getitemdata();
                }
            })
    }

    const Itemmasterdisabled = () => {

        AuthService.PostServiceCall(`api/ItemMasters/ManageInvoice?ItemMasterIdentifier=${itemviewdata.itemMasterIdentifier}&action=${Actionbutton.disable}`)
            .then((res) => {
                //   console.log(res)
                itemeditapi(itemviewdata.itemMasterIdentifier)
                if (res && res !== '' && res.responseMessage === "Success") {
                    document.getElementById('model_one_disable_success').click();
                    Getitemdata();
                }
            })
    }


    //Items master configuration Ends

    //Invoice Type configuration starts
    const [invoicetypeshow, setinvoicetypeshow] = useState(1)
    const [invoicetypedata, setinvoicetypedata] = useState()
    const [invoicetyperesponse, setinvoicetyperesponse] = useState()
    const [invoicetypesearch, setinvoicetypesearch] = useState('')
    const [invoicetypePaginations, SetinvoicetypePaginations] = useState({ Current_page: 1 });
    const [invoiceviewdropdown, setinvoiceviewdropdown] = useState()
    const [invoicedropdownvalue, setinvoicedropdownvalue] = useState('')
    const [Activeflag, setActiveflag] = useState(false)
    const [invoicecofigerror, setinvoicecofigerror] = useState(true)

    const [invoicetypeenbled, setinvoicetypeenbled] = useState(null)
    const [invoicetypeenbledvalue, setinvoicetypeenbledvalue] = useState('')

    const [invoicetypegst, setinvoicetypegst] = useState()

    const handleinvoicetypeDropdownChange = (event) => {
        setinvoicetypeenbledvalue(event.target.value)

        if (event.target.value === "1") {
            setinvoicetypeenbled(true);
        } else if (event.target.value === "2") {
            setinvoicetypeenbled(false);
        } else {
            setinvoicetypeenbled(null);
        }
    };

    const invoicecreate = {
        invoiceTypeName: '',
        invoiceTypeSerialCode: '',
        supplierTradeName: '',
        invoiceTypeCcemail: '',
        invoiceTypeMailSubject: '',
        invoiceTypeMailBody: '',
        marginFlag: false,
        tcsflag: false,
        regUnregFlg: false,
        autoApproveFlag: false,
        invoiceTypeConfigurationIdentifier: '',
        userMasterIdentifier: CurrentUser,
    }

    const [invoicetypeviewdata, setinvoicetypeviewdata] = useState(invoicecreate)
    const [invoiceinput, setinvoiceinput] = useState(invoicecreate)

    const handleQuillChange = (value) => {
        setinvoiceinput((prevInvoiceInput) => ({
            ...prevInvoiceInput,
            invoiceTypeMailBody: value,
        }));
    };

    const Getinvoicetypedata = () => {
        const actualColumnName = columnName !== undefined ? columnName : "InvoiceTypeConfigurationAutoId";
        let apiUrl = `api/invoiceTypeConfigurations/GetAllInvoiceType?page=${invoicetypePaginations.Current_page}&pageSize=${10}&sortField=${actualColumnName}&isAscending=${Ascending}
        &searchTerm=${invoicetypesearch}&SupplierIdentifier=${invoicedropdownvalue}&userIdentifier=${CurrentUser}`;

        if (invoicetypeenbled !== null) {
            apiUrl += `&activeflag=${invoicetypeenbled}`;
        }
        AuthService.GetServiceCall(apiUrl)
            .then((res) => {
                if (res && res !== '' && res.responseData) {
                    setinvoicetypedata(res.responseData)
                    setinvoicetyperesponse(res.responseCount)
                }
            })
    }

    const Getinvoicetypedropdown = () => {
        AuthService.GetServiceCall(`api/invoiceTypeConfigurations/SupplierNameSearchDropDown`)
            .then((res) => {
                if (res && res !== '' && res.responseData) {
                    //   console.log(res.responseData);
                    if (res.responseData) {
                        setinvoiceviewdropdown(res.responseData)
                    }
                }
            })
    }

    // useEffect(() => {
    //     if (CurrentUser) {
    //         Getinvoicetypedata();
    //         Getinvoicetypedropdown();
    //     }
    // }, [invoicetypePaginations, invoicedropdownvalue, invoicetypeenbledvalue, Ascending])

    const invoicetypepageNumbers = [];
    for (let i = 1; i <= Math.ceil(invoicetyperesponse && invoicetyperesponse / 10); i++) {
        invoicetypepageNumbers.push(i);
    }
    const invoiceTypehandlePageChange = (pageNumber) => {
        SetinvoicetypePaginations({ ...invoicetypePaginations, Current_page: invoicetypePaginations.Current_page = pageNumber });
    };

    const handleinvoiceiputchange = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        setinvoiceinput({ ...invoiceinput, [name]: value })

    }
    const handleinvoiceiflagchange = (e) => {
        const { name, checked } = e.target;

        setinvoiceinput((prevInvoiceInput) => ({
            ...prevInvoiceInput,
            [name]: checked,
        }));
    }
    const handleinvoiceinsert = () => {
        if (invoiceinput.invoiceTypeName === '' || invoiceinput.supplierTradeName === '' || invoiceinput.invoiceTypeSerialCode === '') {

            setinvoicecofigerror(false)
        } else {
            AuthService.PostServiceCall('api/invoiceTypeConfigurations/InsetInvoiceType', invoiceinput)
                .then((res) => {
                    //   console.log(res)
                    if (res && res !== '' && res.responseMessage === "Success") {
                        setinvoicecofigerror(true)
                        setinvoicetypeshow(1)
                        Getinvoicetypedata();
                        document.getElementById('invoice_type_insert').click();
                        setinvoiceinput(invoicecreate)
                    }
                })
        }
    }

    const Edittypeapi = (iq) => {
        AuthService.GetServiceCall(`api/invoiceTypeConfigurations/GetInvoiceType?InvoiceTypeIdentifiers=${iq}`)
            .then((res) => {
                // console.log(res)
                if (res.responseData && res.responseData[0]) {
                    setinvoicetypeviewdata({
                        ...invoicetypeviewdata,
                        invoiceTypeName: res.responseData[0].invoiceTypeName,
                        invoiceTypeSerialCode: res.responseData[0].invoiceTypeSerialCode,
                        supplierTradeName: res.responseData[0].supplierTradeName,
                        invoiceTypeCcemail: res.responseData[0].invoiceTypeCcemail,
                        invoiceTypeMailSubject: res.responseData[0].invoiceTypeMailSubject,
                        invoiceTypeMailBody: res.responseData[0].invoiceTypeMailBody,
                        marginFlag: res.responseData[0].marginFlag,
                        tcsflag: res.responseData[0].tcsflag,
                        regUnregFlg: res.responseData[0].regUnregFlg,
                        autoApproveFlag: res.responseData[0].autoApproveFlag,
                        invoiceTypeConfigurationIdentifier: res.responseData[0].invoiceTypeConfigurationIdentifier
                    })
                    setActiveflag(res && res !== '' && res.responseData[0].activeFlag)
                }
            })

        AuthService.GetServiceCall(`api/invoiceTypeConfigurations/GetNoGSTInvoice?InvoiceTypeConfigurationIdentifier=${iq}`)
            .then((res) => {
                if (res && res !== '' && res.responseData) {
                    setinvoicetypegst(res.responseData)
                }
            })
    }

    const handleinvoicetypeedit = (iq) => {
        setinvoicetypeshow(3)
        setinvoicetypeeditview({ ...invoicetypeeditview, Edit: true });
        //   console.log(iq)
        Edittypeapi(iq)
    }


    useEffect(() => {
        setinvoiceinput({
            ...invoiceinput,
            invoiceTypeName: invoicetypeviewdata.invoiceTypeName,
            invoiceTypeSerialCode: invoicetypeviewdata.invoiceTypeSerialCode,
            supplierTradeName: invoicetypeviewdata.supplierTradeName,
            invoiceTypeCcemail: invoicetypeviewdata.invoiceTypeCcemail,
            invoiceTypeMailSubject: invoicetypeviewdata.invoiceTypeMailSubject,
            invoiceTypeMailBody: invoicetypeviewdata.invoiceTypeMailBody,
            marginFlag: invoicetypeviewdata.marginFlag,
            tcsflag: invoicetypeviewdata.tcsflag,
            regUnregFlg: invoicetypeviewdata.regUnregFlg,
            autoApproveFlag: invoicetypeviewdata.autoApproveFlag,
            invoiceTypeConfigurationIdentifier: invoicetypeviewdata.invoiceTypeConfigurationIdentifier,
        })
    }, [invoicetypeviewdata])


    const handleinvoicetypeeditsaveinput = () => {
        if (invoiceinput.invoiceTypeName === '' || invoiceinput.supplierTradeName === '' || invoiceinput.invoiceTypeSerialCode === '') {

            setinvoicecofigerror(false)
        } else {

            AuthService.PostServiceCall(`api/invoiceTypeConfigurations/UpdateInvoiceType?InvoiceTypeConfigurationIdentifier=${invoiceinput.invoiceTypeConfigurationIdentifier}`, invoiceinput)
                .then((res) => {
                    //   console.log(res)
                    if (res && res !== '' && res.responseMessage === "Success") {
                        setinvoicetypeshow(1)
                        Getinvoicetypedata();
                        document.getElementById('invoice_type_insert').click();
                    } else {

                    }

                })
        }
    }

    const handledeleteinvoicetypemaster = () => {
        AuthService.PostServiceCall(`api/invoiceTypeConfigurations/ManagInvoice?InvoiceTypeIdentifier=${invoicetypeviewdata.invoiceTypeConfigurationIdentifier}&action=0`)
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.responseMessage === "Success") {
                    setinvoicetypeshow(1)
                    Getinvoicetypedata();
                    setinvoiceinput(invoicecreate)
                    document.getElementById('invoice_type_delete').click();
                } else if (res && res !== '' && res.responseMessage === "Invoice type is already used, cannot delete") {
                    document.getElementById('invoice_type_exist').click();
                }
            })
    }


    const handleenabletext = () => {
        AuthService.PostServiceCall(`api/invoiceTypeConfigurations/ManagInvoice?InvoiceTypeIdentifier=${invoicetypeviewdata.invoiceTypeConfigurationIdentifier}&action=${Actionbutton.enable}`)
            .then((res) => {
                if (res && res !== '' && res.responseMessage === 'Success') {
                    document.getElementById('invoice_type_alert_Successful').click();
                    SetShow(1)
                    Edittypeapi(invoicetypeviewdata.invoiceTypeConfigurationIdentifier)
                    Getinvoicetypedata();
                } else {

                }
            })
    }

    const handledisabletext = () => {
        AuthService.PostServiceCall(`api/invoiceTypeConfigurations/ManagInvoice?InvoiceTypeIdentifier=${invoicetypeviewdata.invoiceTypeConfigurationIdentifier}&action=${Actionbutton.disable}`)
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.responseMessage === 'Success') {
                    document.getElementById('invoice_type_alert_Successful_dis').click();
                    SetShow(1)
                    Edittypeapi(invoicetypeviewdata.invoiceTypeConfigurationIdentifier)
                    Getinvoicetypedata();
                } else {

                }
            })
    }
    //Invoice Type configuration Ends

    // Globle Invoice starts

    const [paginationnoncmscustom, setpaginationnoncmcscustom] = useState({ Current_page: 1 })
    const [createPageSupplierData, setcreatePageSupplierData] = useState('')
    const [supplierCode, setSupplierCode] = useState('')
    const [customerCode, setCustomerCode] = useState('')
    const [customviewshow, setcustomviewshow] = useState(1)
    const [Editboxaddressbill, seteditboxaddressbill] = useState()
    const [Editboxaddressship, seteditboxaddressship] = useState()
    const [noncmssearch, setnoncmssearch] = useState('')
    const [ShipIdentifier, setShipIdentifier] = useState("")
    const [BillIdentifier, setBillIdentifier] = useState("")

    const [nonCMS, setnonCMS] = useState({  // req obj non-cms
        "nonCMSIdentifier": "",
        "search": '',
        "billingIdentifier": "",
        "shipIdentifier": "",
        "billingStatesCode": '',
        "shippingStatesCode": '',
        "regUnRegFlag": '',
        "sortField": "",
        "descFlag": true,
        "pageSize": 10,
        "currentPage": 1
    })
    const [CMS, setCMS] = useState({    // req obj cms
        "cmsIdentifier": "",
        "search": "",
        "sortField": "",
        "descFlag": true,
        "pageSize": 10,
        "currentPage": 1
    })
    const [NoncmsCreatedata, SetNoncmsCreatedata] = useState({  // non-cms inserted
        "customerMasterIdentifier": "",
        "customerTradeName": "",
        "customerLegalName": "",
        "customerPanNumber": ""
    })
    const [Noncmsbilladdress, setNoncmsbilladdress] = useState({ // bill address insert
        "invoiceCustomerBillAddressIdentifier": "",
        "invoiceCustomerMasterAutoIdentifier": NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier,
        "customerGstin": "",
        "customerBuildingNumber": "",
        "customerBuildingName": "",
        "customerLocation": "",
        "customerDistrict": "",
        "customerPincode": "",
        "customerStateCode": "",
        "billingPos": "",
        "customerPhone": "",
        "customerEmail": "",
        "userIdentifier": CurrentUser,
        "copyFlag": false
    })

    const [Noncmsshipaddress, setNoncmsshipaddress] = useState({    // ship address insert
        "invoiceCustomerShipAddressIdentifier": "",
        "invoiceCustomerMasterAutoIdentifier": (NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier) ? NoncmsCreatedata.customerMasterIdentifier : "",
        "shipGstin": "",
        "shipBuildingNumber": "",
        "shipBuildingName": "",
        "shipLocation": "",
        "shipDistrict": "",
        "shipPincode": "",
        "shipStateCode": "",
        "shipPhone": "",
        "shipEmail": "",
        "userIdentifier": CurrentUser
    })

    // const Noncms c c= {
    //     invoiceCustomerMasterIdentifier: '',
    //     invoiceCustomerMasterAutoId: '',
    //     customerLegalName: '',
    //     userIdentifier: '',
    //     createdDate: '',
    //     updatedDate: '',
    //     deleteFlag: '',
    //     panNumber: '',
    //     tblGstapiinvoiceCustomerShipAddresses: [],
    //     tblGstapiinvoiceCustomerBillAddresses: []
    // }

    useEffect(() => {
        setNoncmsbilladdress({ ...Noncmsbilladdress, invoiceCustomerMasterAutoIdentifier: (NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier) ? NoncmsCreatedata.customerMasterIdentifier : "" })
        setNoncmsshipaddress({ ...Noncmsshipaddress, invoiceCustomerMasterAutoIdentifier: (NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier) ? NoncmsCreatedata.customerMasterIdentifier : "" })
    }, [NoncmsCreatedata])

    const handlenoncmssearchclick = () => {
        setnonCMS({ ...nonCMS, search: noncmssearch, currentPage: 1 })
    }

    const [AllInvoiceInvoiceTypeDrop, setAllInvoiceInvoiceTypeDrop] = useState('') // invoice type identifier
    const [CreateViewCustomerData, setCreateViewCustomerData] = useState([])    // customers Table
    const [CreateViewCustomerDataCount, setCreateViewCustomerDataCount] = useState([]) // ^ count
    const [InvoiceTypeObj, setInvoiceTypeObj] = useState({})    // invoice type object
    const [ViseFlag, SetViseFlag] = useState(false)

    const [GetItems, setGetItems] = useState([])    // items -> dropdown
    const [ItemData, setItemData] = useState({})    // selected items -> data
    const [CurrentItemIdentifier, setCurrentItemidentifier] = useState('')  // selected item identifier
    const [HSNCODE, setHSNCODE] = useState('')  // hsncode for selected item

    const [GetUnits, setGetUnits] = useState([])    // units -> dropdown
    const [CurrentUnitIdentifier, setCurrentUnitIdentifier] = useState('') // current unit identifier

    const [CustomerType, setCustomerType] = useState("1")   //1- non-cms  // 2 - cms
    const [CustomerIdentifier, setCustomerIdentifier] = useState('');   // customers identifier (both)
    const [selectCustomerIdentifier, setselectCustomerIdentifier] = useState('');   // customers identifier (both)
    const [SelectedCustomer, setSelectedCustomer] = useState({});   // selected customer
    const [BillingObj, setBillingObj] = useState({})    //  billing Address for selected customer
    const [ShippingObj, setShippingObj] = useState({})  // shipping Address for selected customer

    const [SupplierStateCode, setSupplierStateCode] = useState("")  // selected invoice type supplier state code
    const [CustomerStateCode, setCustomerStateCode] = useState("")  // selected customer type bill code if not avail ship code
    const [SalesAmountRegUnReg, setsalesAmountRegUnReg] = useState('')  // remarketing sales amount
    const [GstFlag, setGstFlag] = useState(1);  // regunreg
    const [EditFlag, setEditFlag] = useState("1");  // view or edit flag

    const [contractIdentifier, setContractIdentifier] = useState('')    // remarketing contract identifier
    const [ContractCustomer, setContractCustomer] = useState({})    // remarketing contract customer
    const [callStatus, setCallStatus] = useState(0);    // calculation api calling status
    const [CalculateType, setCalculateType] = useState('1');    // calculate tax type
    const [CalculationResponse, setCalculationResponse] = useState({})  // calulate api response


    const [InvoiceRecords, setInvoiceRecords] = useState([])    // records for table view
    const [Search, setSearch] = useState("")    // invoices main table search
    const [InvoiceRec, setInvoiceRec] = useState({})    // selected invoice record
    const [PanValue, setPanValue] = useState('')    // to find the customer by panvalue
    const [viewFlag, setViewFlag] = useState(0) // view flag

    const handleSendmail = () => {
        // console.log(MailCheckMark);
        if (MailCheckMark.length > 0) {
            sendEmailMethod(MailCheckMark);
        }
        else if (MailCheckMark.length == 0) {
            document.getElementById('Email_null_Model').click();

        }
    }

    // useEffect(() => {
    //     handleSendmail();
    // }, [MailCheckMark]);

    const handlsingleemail = (identifier) => {
        //console.log(identifier);
        sendEmailMethod([identifier]);
    };



    const sendEmailMethod = (Identifiers) => {

        // console.log(Identifiers);
        AuthService.PostServiceCall('api/SendMail/SendEmail', Identifiers)
            .then((res) => {
                //console.log(res);
                if (res && res !== '' && res.status === "Success") {
                    // console.log("in")
                    setMailCheckMark((prevMailCheckMark) =>
                        prevMailCheckMark.filter((id) => false)
                    );
                    document.getElementById('Email_Success_Model').click();
                }
                else {
                    //   console.log(res);
                    setMailCheckMark((prevMailCheckMark) =>
                        prevMailCheckMark.filter((id) => false)
                    );
                    document.getElementById('Email_Fail_Model').click();

                }
            })
    }

    // useEffect(() => {
    //     handleSendmailapprovalcredit();
    // }, [CheckMark]);



    // const sendmails = () => {
    //     useEffect(() => {
    //         handleSendmailapprovalcredit();
    //     }, [CheckMark]);
    // }

    const [InvoiceReqObj, setInvoiceReqObj] = useState({    // invoice req obj
        "selected_Calculation": 1,
        "invoice_Type_Identifier": "",
        "contract_Identifier": "0",
        "item_Ideantifier": "",
        "invoice_Amount": 0,
        "salesAmountRegUnReg": 0,
        "marginAmount": 0,
        "supplier_StateCode": "",
        "customer_StateCode": "",
        "gstFlag": 0,
        "invoiceIdentifier": ""
    })
    const [MarginAmt, setMarginAmt] = useState(0)


    useEffect(() => {
        setInvoiceReqObj({
            ...InvoiceReqObj,
            selected_Calculation: Number(CalculateType ? CalculateType : "1"),
            invoice_Type_Identifier: AllInvoiceInvoiceTypeDrop ? AllInvoiceInvoiceTypeDrop : "",
            contract_Identifier: contractIdentifier ? contractIdentifier : "",
            item_Identifier: CurrentItemIdentifier ? CurrentItemIdentifier : "",
            salesAmountRegUnReg: Number(SalesAmountRegUnReg ? SalesAmountRegUnReg : "0"),
            supplier_StateCode: supplierCode,
            customer_StateCode: customerCode,
            invoice_Amount: Number(InvoiceAmt),
            marginAmount: Number(MarginAmt),
            gstFlag: Number(GstFlag)
        })

        // if ((createPageSupplierData ? createPageSupplierData.supplierStateCode : "") === SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] ? SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerStateCode : "") {
        //     setItemData(prev => ({ ...prev, igstrate: 0 }))
        // }
        // else {
        //     setItemData(prev => ({ ...prev, sgstrate: 0, cgstrate: 0 }))
        // }

    }, [CalculateType, AllInvoiceInvoiceTypeDrop, contractIdentifier, CurrentItemIdentifier, customerCode, supplierCode, SelectedCustomer])

    useEffect(() => {
        if (viewFlag !== 0 && EditFlag === "1") {
            DoInvoiceCalculation()
        }
    }, [GstFlag])

    const DoInvoiceCalculation = () => {
        var CalObj = {
            ...InvoiceReqObj,
            selected_Calculation: Number(CalculateType),
            salesAmountRegUnReg: Number(SalesAmountRegUnReg ? SalesAmountRegUnReg : "0"),
            invoice_Amount: Number(InvoiceAmt),
            contract_Identifier: contractIdentifier ? contractIdentifier : "",
            item_Identifier: CurrentItemIdentifier,
            marginAmount: Number(MarginAmt),
            gstFlag: GstFlag
            // selected_Calculation: Number(CalculateType),
            // invoice_Type_Identifier: AllInvoiceInvoiceTypeDrop,
            // contract_Identifier: contractIdentifier,
            // item_Identifier: CurrentItemIdentifier,
            // salesAmountRegUnReg: Number(SalesAmountRegUnReg),
            // supplier_StateCode: createPageSupplierData.supplierStateCode,
            // customer_StateCode: SelectedCustomer && SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses && SelectedCustomer.tblGstapiinvoiceCustomerShipAddresses[0].shipStateCode,
            // invoice_Amount: Number(InvoiceAmt),
            // gstFlag: Number(GstFlag)
        };
        // console.log("CalObj", CalObj)
        AuthService.PostServiceCall('api/Calculation/GetTaxCalculation', CalObj).then((res) => {
            if (res && res !== '' && res.status === "Success") {
                // console.log("calculate Response", res)
                if (res.data) {
                    setCalculationResponse(res.data)
                    if (res.data && res.data.marginAmount)
                        setMarginAmt(Number(res.data.marginAmount))
                    else {
                        setMarginAmt(0)
                    }
                }
            }
        }).catch((err) => console.log("Error:", err));
    }

    // calculation
    useEffect(() => {
        // console.log("2 ", 'callStatus ', callStatus, 'EditFlag ', EditFlag)
        if (callStatus !== 0 && EditFlag === "1") {
            DoInvoiceCalculation();

        }
    }, [callStatus, CalculateType, GstFlag, AllInvoiceInvoiceTypeDrop, CurrentItemIdentifier])

    const GetCustomer = (type, identifier) => {
        //   console.log(identifier)
        if (type === 1) { // non - cms
            if (!identifier) {
                identifier = "";
            }
            // console.log({ ...nonCMS, nonCMSIdentifier: identifier, billingIdentifier: BillIdentifier, shipIdentifier: ShipIdentifier })
            AuthService.PostServiceCall('api/Customer/GetNonCmsCustomer', { ...nonCMS, nonCMSIdentifier: identifier, billingIdentifier: BillIdentifier, shipIdentifier: ShipIdentifier })
                .then((res) => {
                    if (res && res !== '' && res.status === "Success") {
                        if (res.data) {
                            let temp = []
                            // console.log(res.data)
                            setCreateViewCustomerData(res.data)
                            setCreateViewCustomerDataCount(res.recordCount)
                            if (identifier) {
                                setCustomerIdentifier(identifier)
                                setCustomerType(1)
                                setcustomviewshow(1)
                                setSelectedCustomer(res.data[0])

                                setBillingObj(res.data[0] && res.data[0].tblGstapiinvoiceCustomerBillAddresses ? res.data[0].tblGstapiinvoiceCustomerBillAddresses[0] : {})
                                setShippingObj(res.data[0] && res.data[0].tblGstapiinvoiceCustomerShipAddresses ? res.data[0].tblGstapiinvoiceCustomerShipAddresses[0] : {})

                                if (res.data[0] &&
                                    res.data[0].tblGstapiinvoiceCustomerBillAddresses &&
                                    res.data[0].tblGstapiinvoiceCustomerBillAddresses[0] && res.data[0].tblGstapiinvoiceCustomerBillAddresses[0] !== undefined &&
                                    res.data[0].tblGstapiinvoiceCustomerBillAddresses[0] !== temp &&
                                    res.data[0].tblGstapiinvoiceCustomerBillAddresses[0] !== null &&
                                    res.data[0].tblGstapiinvoiceCustomerBillAddresses[0].customerStateCode) {
                                    // console.log(res.data[0].tblGstapiinvoiceCustomerBillAddresses)
                                    setCustomerStateCode(res.data[0].tblGstapiinvoiceCustomerBillAddresses[0].customerStateCode);
                                }
                                else if (res.data[0] &&
                                    res.data[0].tblGstapiinvoiceCustomerShipAddresses &&
                                    res.data[0].tblGstapiinvoiceCustomerShipAddresses[0] && res.data[0].tblGstapiinvoiceCustomerShipAddresses[0] !== undefined &&
                                    res.data[0].tblGstapiinvoiceCustomerShipAddresses[0] !== temp &&
                                    res.data[0].tblGstapiinvoiceCustomerShipAddresses[0] !== null &&
                                    res.data[0].tblGstapiinvoiceCustomerShipAddresses[0].shipStateCode) {
                                    // console.log(res.data[0].tblGstapiinvoiceCustomerShipAddresses)
                                    setCustomerStateCode(res.data[0].tblGstapiinvoiceCustomerShipAddresses[0].shipStateCode);
                                }
                                else
                                    setCustomerStateCode('')
                            }
                        }
                    }
                }).catch((err) => console.log("Error:", err));

        }
        if (type === 2) { // cms
            if (!identifier) {
                identifier = "";
            }
            AuthService.PostServiceCall('api/Customer/GetCmsCustomer', { ...CMS, cmsIdentifier: identifier })
                .then((res) => {
                    if (res && res !== '' && res.status === "Success" && res.data) {
                        //   console.log('customer', res.data)
                        setCreateViewCustomerData(res.data)
                        setCreateViewCustomerDataCount(res.recordCount)
                        if (identifier) {
                            setCustomerType(2)
                            setCustomerIdentifier(identifier)
                            setSelectedCustomer(res.data[0])
                            var tempState = "";
                            if (res.data[0] && res.data[0])
                                tempState = (res.data[0].stateName).toUpperCase();
                            tempState = tempState.slice(0, 2);
                            setCustomerStateCode(tempState);
                        }
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }
    useEffect(() => {
        if (CustomerStateCode) {
            setCustomerCode(CustomerStateCode)
        } else {
            setCustomerCode(CustomerStateCode)
        }
    }, [CustomerStateCode])

    const GetContractCustomer = (identifier) => {
        if (!identifier) {
            identifier = "";
            setContractIdentifier('')
            setcmsNumber("")
            setContractCustomer("")
        }
        else {
            AuthService.PostServiceCall('api/Customer/GetCmsCustomer', { ...CMS, cmsIdentifier: identifier })
                .then((res) => {
                    if (res && res !== '' && res.status === "Success" && res.data && res.data[0]) {
                        if (res && res !== '' && res.data) {
                            setContractIdentifier(identifier)
                            setContractCustomer(res && res !== '' && res.data[0])
                            setcmsNumber(res.data[0].cmscontractNumber)
                        }
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }

    const GetItemsUsingType = () => {
        if (AllInvoiceInvoiceTypeDrop) {
            AuthService.GetServiceCall('api/Invoice/DoGetItemsByType?Identifier=' + AllInvoiceInvoiceTypeDrop)
                .then((res) => {
                    if (res && res !== '' && res.status === "Success" && res.data) {
                        setGetItems(res && res !== '' && res.data)
                    }
                }).catch((err) => console.log("Error:", err));
        }
        else
            setGetItems([])
    }

    const GetInvoiceTypeObject = () => {
        if (AllInvoiceInvoiceTypeDrop) {
            AuthService.GetServiceCall('api/invoiceTypeConfigurations/GetInvoiceType?InvoiceTypeIdentifiers=' + AllInvoiceInvoiceTypeDrop)
                .then((res) => {
                    if (res && res !== '' && res.responseCode === 200 && res.responseData && res.responseData[0]) {
                        setInvoiceTypeObj(res && res !== '' && res.responseData[0])
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }

    const GetItemData = () => {
        if (CurrentItemIdentifier && CurrentItemIdentifier !== '') {
            AuthService.GetServiceCall('api/ItemMasters/GetItemMaster?ItemMasterIdentifier=' + CurrentItemIdentifier)
                .then((res) => {
                    if (res && res !== '' && res !== undefined && res.responseCode === 200 && res.responseData && res.responseData[0]) {

                        //(createPageSupplierData && createPageSupplierData !== undefined && createPageSupplierData !== null ? createPageSupplierData.supplierStateCode : "") ===
                        // (SelectedCustomer && SelectedCustomer !== undefined && SelectedCustomer !== null && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses &&
                        //     SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses !== undefined && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses !== null &&
                        //     SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] !== undefined &&
                        //     SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0] !== null && SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerStateCode)
                        // ? SelectedCustomer.tblGstapiinvoiceCustomerBillAddresses[0].customerStateCode : ""
                        // console.log("supplierCode", supplierCode, "customerCode", customerCode, supplierCode === customerCode)
                        if (supplierCode && supplierCode === customerCode && customerCode) {
                            res.responseData[0].igstrate = 0
                        }
                        else {
                            res.responseData[0].sgstrate = 0
                            res.responseData[0].cgstrate = 0
                        }

                        setItemData(res.responseData[0])
                        setHSNCODE(res.responseData[0].hsncode)
                        if (InvoiceTypeObj.regUnRegFlag === true && ContractCustomer && ContractCustomer.cmscontractNumber !== "") {
                            if (ContractCustomer.cmscontractNumber.slice(0, 1) === "1") { setHSNCODE(res.responseData[0].hsncode1) }
                            if (ContractCustomer.cmscontractNumber.slice(0, 1) === "2") { setHSNCODE(res.responseData[0].hsncode2) }
                            if (ContractCustomer.cmscontractNumber.slice(0, 1) === "3") { setHSNCODE(res.responseData[0].hsncode3) }
                        }
                    }
                })
        }
        else {
            setItemData({})
            setHSNCODE('')
        }
    }

    const GetAllUnitMeasures = () => {
        AuthService.GetServiceCall('api/UnitMeasurement/GetUnitMeasuresDropDown')
            .then((res) => {
                if (res && res !== '' && res.responseCode === 200 && res.responseData) {
                    setGetUnits(res.responseData)
                }
            })
    }

    useEffect(() => {
        GetItemData();
    }, [CurrentItemIdentifier, Editboxaddressbill])

    // Globel Invoice Ends

    // Luffy All invoice starts
    const [Allinvoicepagination, setAllinvoicepagination] = useState({ Current_page: 1 })
    const [CMSCustomerSearch, setCMSCustomerSearch] = useState({ cms: '', noncms: '' })
    const [CMSCustomerPagination, SetCMSCustomerPagination] = useState({ Current_page: 1, });
    const [Invoicecmsnomcms, setInvoicecmcnoncmc] = useState({ All_invoice: 0 })
    const [EditViewFlag, setEditViewFlag] = useState("0")
    const [invoiceloader, setinvoiceloader] = useState(false)

    const [descFlag, setdescFlag] = useState(true)
    const [InvoiceColumnname, setInvoiceColumnname] = useState('')

    const CMSCustomerReqObj = {
        cmsIdentifier: "",
        search: CMSCustomerSearch.cms,
        sortField: "",
        descFlag: true,
        recordCount: '',
        pageSize: 10,
        currentPage: CMSCustomerPagination.Current_page
    }

    const handleClickInvoice = (columnName) => {
        // console.log(columnName);
        // console.log(descFlag)
        // setInvoiceColumnname(columnName);
        // setdescFlag(!descFlag);
        setInvReqObj({ ...InvReqObj, sortField: columnName, descFlag: !(InvReqObj.descFlag) })
        GetAllInvoices();
    }

    const [InvReqObj, setInvReqObj] = useState({
        "search": "",
        "currentPage": Allinvoicepagination.Current_page,
        "pageSize": 10,
        "invoiceTypeIdentifier": "",
        "supplierIdentifier": "",
        "customerType": "",
        "customerIdentifier": "",
        "statusFlag": 3,
        "isMail": false,
        "isCreditNote": false,
        "isProforma": false,
        "sortField": "",
        "descFlag": true,
        "userIdentifier": CurrentUser
    });
    const [NONCMSViewIdentifier, setNONCMSViewIdentifier] = useState("")

    const handleAllInvoiceSearch = () => {
        setInvReqObj({ ...InvReqObj, search: Search, currentPage: 1 })
    }

    const GetAllInvoices = () => {
        // console.log(InvReqObj)
        AuthService.PostServiceCall("api/Invoice/GetAllInvoices", InvReqObj)
            .then((res) => {
                //    console.log(res)
                if (res && res !== '' && res.status === "Success" && res.data) {
                    setInvoiceRecords(res.data)
                    // console.log(InvoiceRecords + "InvoiceRecords")
                    setInvoicecmcnoncmc({ ...Invoicecmsnomcms, All_invoice: res.recordCount })
                }
            })
    }

    function formatDate2(inputDate) {
        if (inputDate) {
            var date = new Date(inputDate);
            var months = [
                "Jan", "Feb", "Mar", "Apr",
                "May", "Jun", "Jul", "Aug",
                "Sep", "Oct", "Nov", "Dec"
            ];
            var day = date.getDate();
            var month = months[date.getMonth()];
            var year = date.getFullYear();
            var formattedDate = day + ' - ' + month + ' - ' + year;
            return formattedDate;
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        if (CurrentUser)
            GetAllInvoices();
    }, [InvReqObj])

    const AllinvoicetypepageNumbers = [];
    for (let i = 1; i <= Math.ceil(Invoicecmsnomcms.All_invoice && Invoicecmsnomcms.All_invoice / 10); i++) {
        AllinvoicetypepageNumbers.push(i);
    }

    const AllinvoiceTypehandlePageChange = (pageNumber) => {
        setAllCheckMark(false)
        setMailCheckMark((prevMailCheckMark) =>
            prevMailCheckMark.filter((id) => false)
        );
        setInvReqObj({ ...InvReqObj, currentPage: pageNumber })
        setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = pageNumber });
    };

    const InvoiceTypeDropDownDetails = () => {
        AuthService.GetServiceCall(`api/Invoice/DoGetInvoiceTypeSupplierDetails?InvoiceTypeIdentifier=${AllInvoiceInvoiceTypeDrop}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    if (res.data && res.data[0]) {
                        setcreatePageSupplierData(res.data[0])
                        setSupplierStateCode(res.data[0] && res.data[0].supplierStateCode)
                    }
                }
            })
    }

    useEffect(() => {
        if (SupplierStateCode)
            setSupplierCode(SupplierStateCode)
        else
            setSupplierCode('')
    }, [SupplierStateCode])

    const handleInvoiceDownload = (identifier, invNoteStatus) => {
        AuthService.GetServiceCall(`api/Excel/DownloadPdfInvoice?identifier=${identifier}&invoiceNoteStatus=${invNoteStatus}`)
            .then((res) => {
                if (res && res !== '' && res.responseCode === "200") {
                    const href = `data:application/pdf;base64,${res.basestring}`;
                    const link = document.createElement('a');
                    link.download = res.filename;
                    link.href = href;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                }
            }).catch((err) => console.log(''));
    }

    useEffect(() => {
        if (AllInvoiceInvoiceTypeDrop !== "") {
            InvoiceTypeDropDownDetails()
            GetItemsUsingType()
            GetInvoiceTypeObject()
        }
        else {
            setGetItems([])
            setcreatePageSupplierData('')
        }
    }, [AllInvoiceInvoiceTypeDrop])

    const formatDate = (inputDate) => {
        var date = inputDate.toString().replace('T', " ").split(' ')[0];
        return date;
    }

    useEffect(() => {
        if (viewFlag !== 0 && ItemData) {
            DoInvoiceCalculation()
        }
    }, [viewFlag, ItemData])

    const handleViewInvoice = (identifier) => {
        setMarginAmt(0)
        handleClearInvoice();
        SetViseFlag(true)
        AuthService.GetServiceCall(`api/Invoice/GetInvoiceById?InvoiceIdentifier=` + identifier)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    // console.log(res)
                    if (res.data) {
                        setInvoiceRec(res.data)
                        setEditFlag("1")
                        setMarginAmt(Number(res.data.marginAmount) ? Number(res.data.marginAmount) : 0)
                        setAllInvoiceInvoiceTypeDrop(res.data.invoiceTypeConfigurationIdentifier)
                        setCustomerIdentifier(res.data.invoiceCustomerMasterIdentifier)
                        GetCustomer(res.data.customerType ? Number(res.data.customerType) : 1, res.data.invoiceCustomerMasterIdentifier)
                        setsalesAmountRegUnReg(res.data.remarkSalesAmount ? Number(res.data.remarkSalesAmount) : 0)
                        GetContractCustomer(res.data.contractIdentifier)
                        setGstFlag(res.data.regUnregFlg === true ? 2 : 1)
                        setCurrentItemidentifier(res.data.invoiceItemMasterIdentifier)
                        setCurrentUnitIdentifier(res.data.unitMeasurementConfigurationIdentifier)
                        setCalculateType(res.data.inputType && res.data.inputType.toString())
                        if (res.data.amountInput && res.data.amountInput != undefined && res.data.amountInput != null) {
                            setInvoiceAmt(res.data.amountInput)
                            setInvoiceReqObj({ ...InvoiceReqObj, invoice_Amount: res.data.amountInput, invoiceIdentifier: identifier });
                        }
                        else {
                            let temp = parseFloat(res.data.invoiceRate)
                            setInvoiceAmt(temp)
                            setInvoiceReqObj({ ...InvoiceReqObj, invoice_Amount: (temp), invoiceIdentifier: identifier });
                        }
                        // setInvoiceAmt(res.data.amountInput)
                        // setInvoiceReqObj({ ...InvoiceReqObj, invoice_Amount: res.data.amountInput, invoiceIdentifier: identifier });

                        setReverseCharge(res.data.reverseCharge)
                        setCallStatus((Math.random() * 1000) + 1)
                        setEditFlag("0")
                        SetShow(4)
                    }
                    setViewFlag((Math.random() * 1000) + 1)
                }
            })
    }

    const handleCreateInvoice = () => {
        SetShow(2)
        handleClearInvoice();
        SetViseFlag(false)
    }

    useEffect(() => {
        if (InvoiceTypeObj) {
            if (InvoiceTypeObj.regUnregFlg) {
            } else {
                setContractIdentifier("")
                setContractCustomer("")
                setcmsNumber("")
            }
        }
    }, [InvoiceTypeObj])

    const handleClearInvoice = () => {
        setAllInvoiceInvoiceTypeDrop('')
        setSelectedCustomer('')
        setCurrentItemidentifier('')
        setCurrentUnitIdentifier('')
        setGetItems([])
        setItemData({})
        setsalesAmountRegUnReg(0)
        setContractIdentifier('')
        setContractCustomer("")
        setcmsNumber("")
        setCreateViewCustomerData([])
        setHSNCODE('')
        setCustomerIdentifier('')
        setCalculateType("1")
        setGstFlag(1)
        setInvoiceAmt(0)
        setInvoiceReqObj({ ...InvoiceReqObj, invoice_Amount: 0 })
        setReverseCharge(false)
        setInvoiceTypeObj({})
        setEditFlag("1")
        setEditViewFlag("0")
        setMarginAmt(0)
        setAllInvoiceErr(true)
        setInvoiceReqObj({ ...InvoiceReqObj, invoiceIdentifier: "" })
    }

    const handleCustomerPanSearch = () => {
        if (PanValue) {
            AuthService.GetServiceCall(`api/Customer/CustomerPanSearch?PanNumber=` + PanValue)
                .then((res) => {
                    if (res && res !== '' && res.status === "Success") {
                        //   console.log(res)
                        if (res.data) {
                            if (res.data.customer) {
                                setCustomerType(Number(res.data.type))
                                setSelectedCustomer(res.data.customer ? res.data.customer : {})
                            }
                            else {
                                setSelectedCustomer('')
                            }
                        }
                    }
                    else {
                        setSelectedCustomer('')
                    }
                })
        }
    }

    const EmptyInvoice = {
        "invoiceIdentifier": "",
        "invoiceCustomerMasterIdentifier": "",
        "billingIdentifier": "",
        "shippingIdentifier": "",
        "invoiceTypeConfigurationIdentifier": "",
        "invoiceItemMasterIdentifier": "",
        "invoiceItemMasterDesc": "",
        "unitMeasurementConfigurationIdentifier": "",
        "customerType": "",
        "amount": "",
        "margin_AMount": "",
        "reMark_Customer_Id": "",
        "reMark_Customer_GST": "",
        "reMark_Customer_Name": "",
        "reverseCharge": "",
        "flgCreditNote": "",
        "reMark_VINNo": "",
        "reMark_PanNumber": "",
        "reMark_ContractStartDate": "",
        "reMark_TotalCostAmount": "",
        "remark_Model": "",
        "reg_Unreg_Flg": false,
        "isProfoma": false,
        "invoiceHSNCode": "",
        "salesAmount": "",
        "reMark_ContractNO": "",
        "amount_Input": "",
        "input_Type": "",
        "vehicleNumber": "",
        "tcsRate": "",
        "userIdentifier": ""
    }

    const [InvoiceObj, setInvoiceObj] = useState(EmptyInvoice)
    const [InvoiceAmt, setInvoiceAmt] = useState(0)
    const [IsProfoma, setIsProfoma] = useState(false)
    const [IsCreditNote, setIsCreditNote] = useState(0)
    const [ReverseCharge, setReverseCharge] = useState(false) // yes / no
    const [AllInvoiceErr, setAllInvoiceErr] = useState(true)

    const OnInvoiceSubmit = (identifier) => {
        if (!CustomerIdentifier || !CurrentItemIdentifier || !InvoiceAmt) {
            // console.log(identifier + "indu")
            setAllInvoiceErr(false);
            return;
        }

        else {
            setAllInvoiceErr(true);
            document.getElementById('invoice_creating').click();
        }
    }

    const OnInvoiceUpdateSubmit = (identifier) => {
        if (AllInvoiceInvoiceTypeDrop === "" || CustomerIdentifier === "" || CurrentItemIdentifier === "" || CurrentItemIdentifier === null || InvoiceAmt === "" || ((InvoiceTypeObj.regUnregFlg) && (ContractCustomer === "" || ContractCustomer.cmscontractNumber === null || ContractCustomer.cmscontractNumber === undefined))) {
            setAllInvoiceErr(false);
        }
        else {
            setAllInvoiceErr(true);
            document.getElementById('invoice_updating').click();
        }
    }


    const SubmitInvoice = (identifier) => {
        var InsertObj = {
            ...InvoiceObj,
            invoiceIdentifier: identifier,
            invoiceCustomerMasterIdentifier: CustomerIdentifier,
            billingIdentifier: BillingObj ? BillingObj.invoiceCustomerBillAddressIdentifier : "",
            shippingIdentifier: ShippingObj ? ShippingObj.invoiceCustomerShipAddressIdentifier : "",
            invoiceTypeConfigurationIdentifier: AllInvoiceInvoiceTypeDrop,
            invoiceItemMasterIdentifier: CurrentItemIdentifier,
            invoiceItemMasterDesc: ItemData && ItemData.itemDescription && ItemData.itemDescription.toString(),
            unitMeasurementConfigurationIdentifier: CurrentUnitIdentifier,
            customerType: CustomerType.toString(),
            amount: CalculationResponse && CalculationResponse.taxableValue ? CalculationResponse.taxableValue.toString().replace(',', '') : "0",
            margin_AMount: CalculationResponse && CalculationResponse.marginAmount ? CalculationResponse.marginAmount.toString().replace(',', '') : "0",
            flgCreditNote: IsCreditNote.toString(),
            isProfoma: IsProfoma,
            invoiceHSNCode: HSNCODE.toString(),
            salesAmount: SalesAmountRegUnReg ? SalesAmountRegUnReg.toString() : "0",
            amount_Input: InvoiceAmt.toString(),
            input_Type: CalculateType ? CalculateType.toString() : "1",
            reverseCharge: ReverseCharge.toString(),
            reg_Unreg_Flg: GstFlag === 2 ? true : false,
            userIdentifier: CurrentUser,
            reMark_Customer_Id: ContractCustomer.contractAutoId ? ContractCustomer.contractAutoId.toString() : "0",
            reMark_Customer_GST: ContractCustomer.customerGST ? ContractCustomer.customerGST : "",
            reMark_Customer_Name: ContractCustomer.customerName ? ContractCustomer.customerName : "",
            reMark_VINNo: ContractCustomer.vinnumber ? ContractCustomer.vinnumber : "",
            reMark_PanNumber: ContractCustomer.panNumber ? ContractCustomer.panNumber : "",
            reMark_ContractStartDate: ContractCustomer.contractStartDate ? ContractCustomer.contractStartDate : "",
            reMark_TotalCostAmount: ContractCustomer.totalCostAmount ? ContractCustomer.totalCostAmount.toString() : "",
            remark_Model: ContractCustomer.model ? ContractCustomer.model : "",
            reMark_ContractNO: ContractCustomer.cmscontractNumber ? ContractCustomer.cmscontractNumber : "",
            tcsRate: CalculationResponse && Number(CalculationResponse.tcsRate) ? Number(CalculationResponse.tcsRate) : 0,
            vehicleNumber: ContractCustomer.vehicleNumber ? ContractCustomer.vehicleNumber : "",
        }

        setInvoiceObj(InsertObj)

        AuthService.PostServiceCall("api/Invoice/InsertUpdateInvoice", InsertObj)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    document.getElementById('Invoice_success_model').click();
                    GetAllInvoices();
                    SetShow(1)

                }
            }).catch((err) => console.log(''));

    }

    const [regFlag, setRegFlag] = useState(false)

    const SearchContractNumberCMS = (cmsNumber) => {

        AuthService.GetServiceCall(`api/Customer/ContractNumberSearch?contractNumber=${cmsNumber}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success" && res.data) {
                    if (res.data) {
                        setContractCustomer(res.data)
                        setcmsNumber(res.data.cmscontractNumber)
                    }
                    else {
                        setContractCustomer("")
                        setcmsNumber("")
                    }
                }
            }).catch((err) => console.log(''));
    }

    const ApproveInvoice = (identifier, isproforma) => {
        AuthService.GetServiceCall(`api/Invoice/DoApproveInvoice?InvoiceIdentifier=${identifier}&isProfoma=${isproforma}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    GetAllInvoices();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log(''));
    }

    const [RejectObj, setRejectObj] = useState({
        "invoiceIdentifier": "",
        "reasonforRejection": "",
        "userIdentifier": CurrentUser
    });

    const RejectInvoice = (identifier, rejectionReason) => {
        var Reject = { ...RejectObj, invoiceIdentifier: identifier, ReasonforRejection: rejectionReason }
        AuthService.PostServiceCall(`api/Invoice/DoRejectInvoice`, Reject)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    GetAllInvoices();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log(''));
    }

    const DeleteInvoice = (identifier) => {
        AuthService.GetServiceCall(`api/Invoice/DoDeleteInvoice?InvoiceIdentifier=${identifier}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    GetAllInvoices();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log(''));
    }

    const RaiseCreditNote = (identifier, status) => {
        AuthService.GetServiceCall(`api/Invoice/DoUpdateCreditNote?InvoiceIdentifier=${identifier}&InvoiceNoteStatus=${status}&UserIdentifier=${CurrentUser}`)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    Navigate('/All_credit_n')
                    GetAllInvoices();
                }
                else {
                    //   console.log(res);
                }
            }).catch((err) => console.log(''));
    }

    // Luffy All invoice Ends


    // Pending Invoice Starts


    const [pendinginvoiceshow, setpendinginvoiceshow] = useState(1)
    const [pendinginvoicedata, setpendinginvoicedata] = useState()
    const [pendinginvoiceresponse, setpendinginvoicereponse] = useState()
    const [pendinginvoicesearch, setpendinginvoicesearch] = useState('')
    const [pendinginvoicePaginations, SetpendinginvoicePaginations] = useState({ Current_page: 1, });
    const [pendinginvoiceselect, setpendinginvoiceselect] = useState({ suppliername: '', invoicetype: '' })
    const [pendingcreatedropdownvalue, setpendingcreatedropdownvalue] = useState('')
    const [pendingcreatesuppliwrdata, setpendingcreatesuppliwrdata] = useState([])
    const [CMSCustomerDataObj, setCMSCustomerDataObj] = useState()
    const [Pendingcustomerrescount, setPendingcustomerrescount] = useState()
    const [editidentifier, seteditidentifier] = useState({ create_edit: '', editcmsidentifier: '', copy_flag_edit: true })
    const [noncmsinvoicepagination, setnoncmsinvoicepagination] = useState({ Current_page: 1 });
    const [noncmsinvoicedatacount, setnoncmsinvoicedatacount] = useState();
    // const[noncmsidentifier,setnoncmsidentifier] = useState("");
    const [noncmsinvoicedata, setnoncmsinvoicedata] = useState();

    const [pendingcmserr, serpendingcmserr] = useState({
        noncms: true,
        cms: true,
        Edit_noncms: '1',
        bill_address: '0',
        ship_address: '0',
        nomcmsbilladdresserr: '1',
        nomcmsshipaddresserr: '1',
        savecancel: '1',
        create_sup_gst_err: true,
        create_pan_err: false,
        shipgstin_err: false
    })


    const [Pendinginvoiceset, setPendinginvoiceset] = useState({
        search: "",
        pageSize: 5,
        currentPage: 1,
        invoiceTypeIdentifier: "",
        supplierIdentifier: "",
        customerType: "",
        customerIdentifier: "",
        statusFlag: 3,
        isMail: false,
        isCreditNote: false,
        isProforma: false,
        sortField: "",
        descFlag: false,
        userIdentifier: CurrentUser
    })


    const noncmsgst = (type, id) => {
        setPendinginvoiceset({ ...Pendinginvoiceset, customerIdentifier: id, customerType: type })
        // CustomerGetinvoice();
    }

    const CustomerGetinvoice = () => {
        AuthService.PostServiceCall("api/Invoice/GetAllInvoices", Pendinginvoiceset)
            .then((res) => {
                // console.log("invoice data:", res)
                if (res && res !== '' && res.data) {
                    setnoncmsinvoicedata(res.data);
                    // console.log(res.data.length)
                    setnoncmsinvoicedatacount(res.recordCount);
                }
            })
    }

    useEffect(() => {
        if (Pendinginvoiceset && Pendinginvoiceset.customerIdentifier) {
            CustomerGetinvoice();
        }
    }, [Pendinginvoiceset])

    const cmspagenumbers = [];
    for (let i = 1; i <= Math.ceil(noncmsinvoicedatacount && noncmsinvoicedatacount / 5); i++) {
        cmspagenumbers.push(i);
    }

    const pendinginvoicepageNumbers = [];
    for (let i = 1; i <= Math.ceil(pendinginvoiceresponse && pendinginvoiceresponse / 5); i++) {
        pendinginvoicepageNumbers.push(i);
    };
    const handlecmspagechange = (pagenumber) => {
        setMailCheckMark((prevMailCheckMark) =>
            prevMailCheckMark.filter((id) => false)
        );
        setAllCheckMark(false);
        setnoncmsinvoicepagination({ ...noncmsinvoicepagination, Current_page: noncmsinvoicepagination.Current_page = pagenumber })
        setPendinginvoiceset({ ...Pendinginvoiceset, currentPage: pagenumber })
    }
    const pendinginvoicehandlePageChange = (pageNumber) => {
        SetpendinginvoicePaginations({ ...pendinginvoicePaginations, Current_page: pendinginvoicePaginations.Current_page = pageNumber });
    };
    // useEffect(() => {
    //     Getpendinginvoice();
    // }, [pendinginvoicePaginations, pendinginvoiceselect])




    const GetCMSCustomerDataObj = () => {
        AuthService.PostServiceCall('api/Customer/GetCmsCustomer', CMSCustomerReqObj)
            .then((res) => {
                if (res && res !== '' && res.data) {

                    setCMSCustomerDataObj(res.data)
                    setPendingcustomerrescount(res.recordCount)
                }
            })
    }
    const CMSCustomerPaginationNumbers = [];
    for (let i = 1; i <= Math.ceil(Pendingcustomerrescount && Pendingcustomerrescount / 10); i++) {
        CMSCustomerPaginationNumbers.push(i);
    }
    const handleCMSCustomerPaginationNumber = (pageNumber) => {
        SetCMSCustomerPagination({ ...CMSCustomerPagination, Current_page: CMSCustomerPagination.Current_page = pageNumber });
    }
    useEffect(() => {
        if (CurrentUser)
            GetCMSCustomerDataObj();
    }, [CMSCustomerPagination])

    const NON_CMSCustomerPaginationNumbers = [];
    for (let i = 1; i <= Math.ceil(CreateViewCustomerDataCount && CreateViewCustomerDataCount / 10); i++) {
        NON_CMSCustomerPaginationNumbers.push(i);
    }
    const handleNON_CMSCustomerPaginationNumber = (pageNumber) => {
        setnonCMS({ ...nonCMS, currentPage: pageNumber })
        setpaginationnoncmcscustom({ ...paginationnoncmscustom, Current_page: paginationnoncmscustom.Current_page = pageNumber });
    };

    // const createnoncmsinput = (e) => {
    //     e.preventDefault();
    //     const { name, value } = e.target;
    //     const trimmedValue = value.trim(); // Trim the value to remove leading and trailing whitespace
    //     SetNoncmsCreatedata({ ...NoncmsCreatedata, [name]: trimmedValue });
    //     if (name === 'customerPanNumber') {
    //         validatePAN(trimmedValue);
    //     }
    // }

    const createnoncmsinput = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        SetNoncmsCreatedata({ ...NoncmsCreatedata, [name]: value });
        if (name === 'customerPanNumber') {
            validatePAN(value);
        }
    }

    const validatePAN = (panNumber) => {
        const panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
        const isValidPAN = panRegex.test(panNumber);
        // console.log(isValidPAN)
        // serpendingcmserr({ ...pendingcmserr, create_pan_err: isValidPAN })
        serpendingcmserr({ ...pendingcmserr, create_pan_err: pendingcmserr.create_pan_err = isValidPAN })
    };

    const validateGSTIN = (gstin) => {
        const gstinRegex = /^[0-9]{2}[A-Za-z]{5}\d{4}[A-Za-z]\d[A-Za-z\d]\w$/i;
        const isValidGSTIN = gstinRegex.test(gstin);
        serpendingcmserr(prev => ({ ...prev, create_sup_gst_err: isValidGSTIN }))
        return isValidGSTIN;
    };


    const validateGSTIN_ship = (gstin) => {
        const gstinRegex = /^[0-9]{2}[A-Za-z]{5}\d{4}[A-Za-z]\d[A-Za-z\d]\w$/i;
        const isValidGSTINship = gstinRegex.test(gstin);
        serpendingcmserr({ ...pendingcmserr, shipgstin_err: isValidGSTINship })
        return isValidGSTINship;
    };

    const [Getnoncmscustomerid, setGetnoncmscustomerid] = useState('')
    
    const trimedtext = (text) => {
        const trimmedText = text.trim().replace(/\s+/g, ' ');
        return trimmedText
    }
   
    const createcustomernoncms = () => {
        const trimmedTradeName = trimedtext(NoncmsCreatedata.customerTradeName.trim());
        const trimmedLegalName = trimedtext(NoncmsCreatedata.customerLegalName.trim());
        const trimmedPanNumber = trimedtext(NoncmsCreatedata.customerPanNumber.trim());
        serpendingcmserr({ ...pendingcmserr, noncms: false })
        if (trimmedTradeName !== '' && trimmedLegalName !== '' && trimmedPanNumber !== '' &&
            pendingcmserr.create_pan_err === true) {
            AuthService.PostServiceCall('api/Customer/InsertUpdateNonCmsCustomer', { ...NoncmsCreatedata, customerTradeName: trimmedTradeName,customerLegalName:trimmedLegalName,customerPanNumber:trimmedPanNumber })
                .then((res) => {
                    if (res && res !== '' && res.status === "Success" && res.data) {
                        // console.log(res)
                        seteditidentifier({ ...editidentifier, create_edit: res.data })
                        // SetNoncmsCreatedata({ ...NoncmsCreatedata, customerMasterIdentifier: res.data })
                        SetNoncmsCreatedata({ ...NoncmsCreatedata, customerMasterIdentifier: NoncmsCreatedata.customerMasterIdentifier = res.data })
                        // console.log(NoncmsCreatedata)
                        setGetnoncmscustomerid(res.data)
                        GetCMSCustomerDataObj();
                        GetCustomer(1, '');
                        serpendingcmserr({ ...pendingcmserr, Edit_noncms: '2' })
                        document.getElementById('model_noncmserg').click()
                    }
                })
        } else {
            serpendingcmserr({ ...pendingcmserr, noncms: false })
        }
    }

    const handlechangebilladdress = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        if (name === 'customerGstin') {
            validateGSTIN(value);
            setNoncmsbilladdress({ ...Noncmsbilladdress, [name]: value })
        }
        else if (name === 'customerPincode') {
            if (value.length <= 6)
                setNoncmsbilladdress({ ...Noncmsbilladdress, [name]: value })
        }
        else if (name === 'customerPhone') {
            if (value.length <= 10)
                setNoncmsbilladdress({ ...Noncmsbilladdress, [name]: value })
        } else {
            setNoncmsbilladdress({ ...Noncmsbilladdress, [name]: value })
        }
    }

    const billaddressbox = () => {
        AuthService.PostServiceCall('api/Customer/AddUpdateBillAddress', Noncmsbilladdress)
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.status === "Success") {
                    // seteditboxaddressbill(res.data.billAddresses)
                    Getnoncmscustomerdata();
                    GetCustomer(1, '')
                    serpendingcmserr({ ...pendingcmserr, bill_address: '3' })
                } else {

                }
            })
    }

    const noncmsbilladdress = () => {
        serpendingcmserr({ ...pendingcmserr, nomcmsbilladdresserr: '1' })
        if (Noncmsbilladdress && Noncmsbilladdress.billingPos.length > 2) {
            serpendingcmserr({ ...pendingcmserr, nomcmsbilladdresserr: '2' })
        } else {
            billaddressbox();
            GetCustomer(1, '')
        }
    }

    const handlechangeshipaddress = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        if (name === 'shipGstin') {
            validateGSTIN_ship(value);
            setNoncmsshipaddress({ ...Noncmsshipaddress, [name]: value })
        }
        else if (name === 'shipPincode') {
            if (value.length <= 6)
                setNoncmsshipaddress({ ...Noncmsshipaddress, [name]: value })
        }
        else if (name === 'shipPhone') {
            if (value.length <= 10)
                setNoncmsshipaddress({ ...Noncmsshipaddress, [name]: value })
        }
        else {
            setNoncmsshipaddress({ ...Noncmsshipaddress, [name]: value })
        }
    }

    const Noncmsshipaddresssave = () => {
        serpendingcmserr({ ...pendingcmserr, nomcmsshipaddresserr: '2' })


        AuthService.PostServiceCall('api/Customer/AddUpdateShipAddress', Noncmsshipaddress)
            .then((res) => {
                if (res && res !== '' && res.status === "Success") {
                    Getnoncmscustomerdata()
                    serpendingcmserr({ ...pendingcmserr, ship_address: '3' })
                    GetCustomer(1, '')
                }
            })

    }

    const shipadressboxedit = (uq) => {
        serpendingcmserr({ ...pendingcmserr, ship_address: '2' })
        AuthService.GetServiceCall(`api/Customer/GetAddressByIdentifier?Type=${1}&Identifier=${uq}`)
            .then((res) => {
                if (res && res !== '' && res.data)
                    setNoncmsshipaddress({
                        ...Noncmsshipaddress,
                        invoiceCustomerShipAddressIdentifier: res.data.invoiceCustomerShipAddressIdentifier,
                        shipGstin: res.data.shipGstin,
                        shipBuildingNumber: res.data.shipBuildingNumber,
                        shipBuildingName: res.data.shipBuildingName,
                        shipLocation: res.data.shipLocation,
                        shipDistrict: res.data.shipDistrict,
                        shipPincode: res.data.shipPincode,
                        shipStateCode: res.data.shipStateCode,
                        shipPhone: res.data.shipPhone,
                        shipEmail: res.data.shipEmail,
                    })
            })
    }

    const billadressboxedit = (uq) => {
        serpendingcmserr({ ...pendingcmserr, bill_address: '2' })
        AuthService.GetServiceCall(`api/Customer/GetAddressByIdentifier?Type=${0}&Identifier=${uq}`)
            .then((res) => {
                //   console.log(res)
                if (res && res !== '' && res.data)
                    setNoncmsbilladdress({
                        ...Noncmsbilladdress,
                        invoiceCustomerBillAddressIdentifier: res.data.invoiceCustomerBillAddressIdentifier,
                        customerGstin: res.data.customerGstin,
                        customerBuildingNumber: res.data.customerBuildingNumber,
                        customerBuildingName: res.data.customerBuildingName,
                        customerLocation: res.data.customerLocation,
                        customerDistrict: res.data.customerDistrict,
                        customerPincode: res.data.customerPincode,
                        customerStateCode: res.data.customerStateCode,
                        customerPhone: res.data.customerPhone,
                        customerEmail: res.data.customerEmail,
                        billingPos: res.data.billingPos,
                        copyFlag: res.data.shippingRefId !== 0 ? true : false
                    })
                       seteditidentifier({ ...editidentifier, copy_flag_edit: res.data.shippingRefId !== 0 ? true : false })
            })
    }

    const billaddressdelete = (uq) => {
        AuthService.PostServiceCall(`api/Customer/DeleteAddress?AddressIdentifier=${uq}&isBillAddress=${true}`)
            .then((res) => {
                //   console.log(res)
                if (res) {
                    setNoncmsbilladdress();
                    Getnoncmscustomerdata();
                    GetCustomer(1, '')
                }
            })
    }

    const shipadressboxdelete = (uq) => {
        AuthService.PostServiceCall(`api/Customer/DeleteAddress?AddressIdentifier=${uq}&isBillAddress=${false}`)
            .then((res) => {
                if (res) {
                    setNoncmsshipaddress();
                    Getnoncmscustomerdata()
                    GetCustomer(1, '')
                }
            })
    }

    const non_cms_Edit_show = () => {
        serpendingcmserr({ ...pendingcmserr, Edit_noncms: '3' })
        SetNoncmsCreatedata({ ...NoncmsCreatedata, customerMasterIdentifier: editidentifier.create_edit })
    }



    const Editnoncmstable = (iq) => {
        setGetnoncmscustomerid(iq)
        Getnoncmscustomerdata();
        seteditidentifier({ ...editidentifier, editcmsidentifier: iq })
        serpendingcmserr({ ...pendingcmserr, savecancel: '2' })
    }

    // const Geteditnoncmsdata = () => {
    //     AuthService.PostServiceCall('api/Customer/GetCmsCustomer',)
    //         .then((res) => {

    //         })
    // }
    const [cusidentify, setcusidentify] = useState('')

    useEffect(() => {
        if (Getnoncmscustomerid) {
            // setcusidentify(Getnoncmscustomerid)
            Getnoncmscustomerdata();
            validatePAN(NoncmsCreatedata.customerPanNumber)
        }
    }, [Getnoncmscustomerid])

    const [noncmssetstate, setnoncmssetstate] = useState({
        customerTradeName: '',
        customerLegalName: '',
        customerPanNumber: '',
        customerMasterIdentifier: ''
    })

    const [handleCustomerObj, sethandleCUstomerObj] = useState('')

    var response = {};
    const Getnoncmscustomerdata = async () => {
        if (Getnoncmscustomerid) {
            try {
                const res = await AuthService.PostServiceCall('api/Customer/GetNonCmsCustomer', { nonCMSIdentifier: Getnoncmscustomerid });

                if (res && res !== '' && res.data && res.data[0]) {
                    response = res.data[0];
                    const invoiceidentifier = res.data[0].invoiceCustomerMasterIdentifier;
                    noncmsgst("1", res.data[0].invoiceCustomerMasterIdentifier);

                    SetNoncmsCreatedata((prevNoncmsCreatedata) => ({
                        ...prevNoncmsCreatedata,
                        customerTradeName: res.data[0].customerTradeName.trim(),
                        customerLegalName: res.data[0].customerLegalName.trim(),
                        customerPanNumber: res.data[0].panNumber.trim(),
                        customerMasterIdentifier: Getnoncmscustomerid
                    }));

                    validatePAN(res.data[0].panNumber);

                    if (res.data[0].tblGstapiinvoiceCustomerBillAddresses)
                        seteditboxaddressbill(res.data[0].tblGstapiinvoiceCustomerBillAddresses);
                    else
                        seteditboxaddressbill([]);

                    if (res.data[0].tblGstapiinvoiceCustomerShipAddresses)
                        seteditboxaddressship(res.data[0].tblGstapiinvoiceCustomerShipAddresses);
                    else
                        seteditboxaddressship([]);

                    if (res.data[0].tblGstapiinvoiceCustomerBillAddresses.length && res.data[0].tblGstapiinvoiceCustomerShipAddresses.length)
                        serpendingcmserr({ ...pendingcmserr, bill_address: '3', ship_address: '3', Edit_noncms: '2', savecancel: '2' });
                    else if (res.data[0].tblGstapiinvoiceCustomerShipAddresses.length)
                        serpendingcmserr({ ...pendingcmserr, ship_address: '3', Edit_noncms: '2', savecancel: '2' });
                    else if (res.data[0].tblGstapiinvoiceCustomerBillAddresses.length)
                        serpendingcmserr({ ...pendingcmserr, bill_address: '3', Edit_noncms: '2', savecancel: '2' });
                    else if (res.data[0].tblGstapiinvoiceCustomerBillAddresses.length === 0 && res.data[0].tblGstapiinvoiceCustomerShipAddresses.length === 0)
                        serpendingcmserr({ ...pendingcmserr, Edit_noncms: '2', ship_address: '0', bill_address: '0' });
                    else {
                        seteditboxaddressbill([]);
                        seteditboxaddressship([]);
                    }
                }
            } catch (error) {
                // Handle error
                console.error("Error:", error);
            }

            sethandleCUstomerObj(Math.random(1000) * 2);
        }
    };


    // useEffect(() => {
    //     if (response && response)
    //         SetNoncmsCreatedata((prevNoncmsCreatedata) => ({
    //             ...prevNoncmsCreatedata,
    //             // invoiceCustomerMasterIdentifier: res.data[0].invoiceCustomerMasterIdentifier,
    //             customerTradeName: response.customerTradeName,
    //             customerLegalName: response.customerLegalName,
    //             customerPanNumber: response.panNumber,
    //             customerMasterIdentifier: Getnoncmscustomerid
    //         }));
    // }, [handleCustomerObj])

    const handlesavenoncmsedit = () => {
        serpendingcmserr({ ...pendingcmserr, create_pan_err: true })

        if (NoncmsCreatedata.customerTradeName !== '' && NoncmsCreatedata.customerLegalName !== '' && NoncmsCreatedata.customerPanNumber !== '' &&
            pendingcmserr.create_pan_err === true) {
            AuthService.PostServiceCall('api/Customer/InsertUpdateNonCmsCustomer', { ...NoncmsCreatedata, customerMasterIdentifier: Getnoncmscustomerid })
                .then((res) => {
                    //   console.log(res)
                    serpendingcmserr({ ...pendingcmserr, Edit_noncms: '2' })
                    document.getElementById('model_noncmserg').click()
                    GetCustomer(1, '');
                })
        } else {
            serpendingcmserr({ ...pendingcmserr, noncms: false })
            // serpendingcmserr({ ...pendingcmserr, create_pan_err: false })
            // alert('varala')

        }
    }



    const create_customer_pending_approvel_cms = () => {
        serpendingcmserr({ ...pendingcmserr, bill_address: '0', ship_address: '0', Edit_noncms: '1', savecancel: '1', noncms: true })
        SetNoncmsCreatedata({
            "customerMasterIdentifier": "",
            "customerTradeName": "",
            "customerLegalName": "",
            "customerPanNumber": ""
        })
    }

    const Addaddressbill = () => {
        seteditidentifier({ ...editidentifier, copy_flag_edit: true })
        serpendingcmserr({ ...pendingcmserr, bill_address: '2', nomcmsbilladdresserr: '1' })
        setNoncmsbilladdress({
            ...Noncmsbilladdress,
            "invoiceCustomerBillAddressIdentifier": "",
            "invoiceCustomerMasterAutoIdentifier": NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier,
            "customerGstin": "",
            "customerBuildingNumber": "",
            "customerBuildingName": "",
            "customerLocation": "",
            "customerDistrict": "",
            "customerPincode": "",
            "customerStateCode": "",
            "billingPos": "",
            "customerPhone": "",
            "customerEmail": "",
            "userIdentifier": CurrentUser,
            "copyFlag": false
        })
    }
    useEffect(() => {
        if (CurrentUser) {
            Getitemdata();
            Getinvoicetype();
        }
    }, [itemPaginations, itemdropdownvalue, itemenabledvalue, Ascending])

    const Addaddressship = () => {
        serpendingcmserr({ ...pendingcmserr, ship_address: '2', nomcmsshipaddresserr: '1' })
        setNoncmsshipaddress({
            ...Noncmsshipaddress,
            "invoiceCustomerShipAddressIdentifier": "",
            "invoiceCustomerMasterAutoIdentifier": NoncmsCreatedata && NoncmsCreatedata.customerMasterIdentifier,
            "shipGstin": "",
            "shipBuildingNumber": "",
            "shipBuildingName": "",
            "shipLocation": "",
            "shipDistrict": "",
            "shipPincode": "",
            "shipStateCode": "",
            "shipPhone": "",
            "shipEmail": "",
            "userIdentifier": CurrentUser
        })
    }

    const Customernoncmsdelete = () => {
        // console.log(del);
        if (Getnoncmscustomerid && Getnoncmscustomerid !== undefined && Getnoncmscustomerid !== null) {
            AuthService.PostServiceCall(`api/Customer/DeleteCustomer?AddressIdentifier=${Getnoncmscustomerid}`)
                .then((res) => {
                    if (res && res !== undefined && res !== null) {
                        if (res.errorMsg === "Customer is used by some invoice and cannot be deleted") {
                            document.getElementById('alert_delete_customer').click();
                            //document.getElementById('pills-home-tab').click();
                            // console.log(res.errorMsg)
                        }
                        else if (res.errorMsg === "" || res.status === "Success") {
                            SetShow(3);
                            GetCustomer(1, "");
                            document.getElementById('pills-home-tab').click();
                        }
                        else {
                            console.error("Error occurred during deletion:", res.errorMsg);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error occurred during deletion:", error);
                });
        }
    };

    // Pending invoice ends6

    const [pagenotfound, setpagenotfound] = useState(false)

    function InvalidRouteHandler() {
        setpagenotfound(true);
        sessionStorage.clear()
    }

    const [Emailinvoicesearch, setEmailinvoicesearch] = useState('')

    return (
        <E_invoice_context.Provider value={{
            Modules, setModules, Emailinvoicesearch, setEmailinvoicesearch, invoicetypesearch, setinvoicetypeenbledvalue, setinvoicetypeenbled, unitsearch,
            show, SetShow, setstateconfigfdata, setstatenamecode, Getstatecodeconfig, CurrentUser, Getinseretstatecodeconfig, itemsearch,
            Createcs, statenamecode, handlestatenamecodechange, handlesubmitstate, suplierinserstatecode, setitemenabled,
            Setcreatecs, StatehandlePageChange, StatepageNumbers, StatePaginations,
            Edit_click, Stateconfigdata, staterespone, GetStateconfigdata,
            Bill, stateconfig, setstateconfig, setstatesearch, noncmsinvoicedatacount, statesearch,
            SetBill, setisAuthorized, handlesingin, decryptData, seterrfieldone,
            Ship, password, setpassword, Err, Seterr, isAuthorized, setcustomviewshow, customviewshow,
            SetShip, config, setconfig, username, setusername, suplierstatecode, setsuplierstatecode, stateCodeError, SetStatePaginations,
            setStateNameError, stateNameError, setStateCodeError, setstatecodevald, statecodevald, Ascending, setAllinvoicepagination,
            sessionStartTime, sessionEndTime, decodedPayload,
            // userMaster 
            usemaster_config, InvoiceTypes, setInvoiceTypes, ProfomaTypes, setProfomaTypes, setGetRecords,
            GetRecords, UserRecords, UserView, setUserView, handleUserView, handleUserSearch,
            userPages, setUserPages, Userresponsed, UserhandlePageChange, UserPaginations,
            UserpageNumbers, handleCreateNew, GetUserRecords, Edituser, setedituser, setuserfielderr, userfielderr, validateemail,

            //Units of Measurement props Starts

            unitsshow, setunitshow, unitdata, setunitinpt, setunitsearch, Getunitdata, unitinput, handleunitchanges, handleunitinputedit, UnitDelete, unitenabled,
            unithandlePageChange, unitpageNumbers, unitresponse, unitPaginations, handleunitinsertsave, handleeditunitinsertsave, Actionbutton, unitdisabled,
            setunitmeasurevald, unitmeasurevald, unitactiveflag, SetunitPaginations,

            //Units of Measurement props Ends

            //Items master configuration props starts
            setitemshow, itemshow, itemdata, setitemsearch, Getitemdata, itempageNumbers, itemdropdownvalue, setitemenabledvalue, itemdropdowncreate, setcreateitemmaster,
            itemPaginations, itemhandlePageChange, itemresponse, itemdropdown, setitemdropdownvalue, handleitemDropdownChange, itemenbled, itemenabledvalue, itemcreateObj,
            setitemdropdownvaluecreate, handleitemcreatechange, createitemmaster, handleitemsaveinput, errfieldone, handleedititem, handleitemeditsaveinput,
            handledeleteitemmaster, Itemmasterdisabled, Itemmasterenabled, setitemeditview, itemeditview, itemeditflag, SetitemPaginations, Getinvoicetype,
            //Items master configuration props end

            //Invoice Type configuration props starts

            setinvoicetypeshow, invoicetypeshow, invoicetypedata, setinvoicetypesearch, Getinvoicetypedata, invoicetypepageNumbers, handleinvoiceiflagchange, handleenabletext, handledisabletext,
            invoiceTypehandlePageChange, invoicetypePaginations, invoicetyperesponse, invoiceviewdropdown, handleinvoiceinsert, handledeleteinvoicetypemaster,
            setinvoicedropdownvalue, handleinvoiceiputchange, invoiceinput, handleQuillChange, handleinvoicetypeedit, handleinvoicetypeeditsaveinput, setinvoiceinput,
            setinvoicecofigerror, invoicecofigerror, invoicecreate, handleinvoicetypeDropdownChange, invoicetypeenbledvalue, setinvoicetypeeditview, invoicetypeeditview,
            invoicetypegst, Activeflag, Edittypeapi, SetinvoicetypePaginations, invoicedropdownvalue, Getinvoicetypedropdown,
            //Invoice Type configuration props End

            //Report Dharshan props Starts
            InvoiceDataMaster, setInvoiceDataMaster, enddate, setenddate, startdate, setstartdate, handle_invoice_download_data, handleenddate, handlestartdate, handle_download_data,
            setInoiceFileData, InvoiceFileData,
            //Reports Dharshan props Ends

            // All Creadit Dharshan props Starts
            creditpagenumbers, creditPagination, setCreditPagination, handlecredit_enable_value, setcreditsupplierDropdown,
            setcreditsearchvalue, setcreditinvoicetypedropdown, setCreditShow, creditshow, getcreditInvoicedata, credithandlePageChange,
            creditdata, setcreditdata, creditresponsedata, handleviewcreditdata, setcreditviewdata, creditviewdata, setcreditinvoicerecord, creditinvoicerecord, handlecreditAllInvoiceSearch,
            creditsearchvalue, creditsalesamnt, creditcustomercode, credititem, creditgstflag, creditsuppcode, creditinvoiceamnt,
            creditsuppliertype, creditinvoicetype, creditcalculatetype, creditinvoicedata, setcreditcalculatedata, creditcalculatedata, creditIsProfoma, setcreditIsProfoma,
            handleApprovecreditInvoice, handleRejectcreditInvoice, creditRejectObj, creditsetRejectObj, handleDownloadCreditData,
            // All Creadit Dharshan props Ends

            //Credit pending props Starts
            pendingcreditdata, pendingcreditresponsedata, pendingcreditviewdata, setpendingcreditresponsedata, setpendingcreditviewdata,
            setpendingCreditShow, setpendingcreditdata, pendingcreditshow, pendingcreditinvoicerecord, setcreditinvoicerecord, getPendingCreditInvoiceData,
            handlependingcreditviewdata, handlependingcreditsearchdata, setpendingsetcreditinvoicerecord, pendingcreditinvoicerecord,
            pendingcreditsearch, setpendingcreditsearch, pendingcredithandlePageChange, pendingcreditpagenumbers, pendingcreditPagination,
            pendingcreditinvoicedata, pendingcreditcontractdata, pendingcreditcalculatedata, pendingcreditsalesamnt,
            pendingcreditcustomercode, pendingcredititem, pendingcreditgstflag, pendingcreditsuppcode, pendingcreditinvoiceamnt,
            pendingcreditsuppliertype, pendingcreditinvoicetype, pendingcreditcalculatetype, pendingcreditcalculatedata,
            handleRejectpendingcreditInvoice, handleApprovependingcreditInvoice,
            //credit pending props ends
            // Customer Master props Starts

            // Customer Master props Ends.

            // Diwakar All invoice starts
            InvoiceRecords, InvReqObj, setInvReqObj, handleViewInvoice, formatDate, Search, setSearch, AllinvoicetypepageNumbers,
            handleAllInvoiceSearch, createPageSupplierData, AllInvoiceInvoiceTypeDrop, setAllInvoiceInvoiceTypeDrop,
            handleCreateInvoice, GetCustomer, CreateViewCustomerData, CustomerType, OnInvoiceSubmit, OnInvoiceUpdateSubmit,
            GetItemsUsingType, setCreateViewCustomerData, setReverseCharge, InvoiceRec, setInvoiceRec,
            setCreateViewCustomerDataCount, CurrentItemIdentifier, setCurrentItemidentifier, Allinvoicepagination,
            GetItems, ItemData, GetItemData, GetUnits, CurrentUnitIdentifier, setCurrentUnitIdentifier,
            CustomerIdentifier, setCustomerIdentifier, InvoiceReqObj, setInvoiceReqObj, setCallStatus,
            CalculationResponse, CalculateType, setCalculateType, handleCustomerPanSearch, PanValue, setPanValue,
            HSNCODE, InvoiceAmt, setInvoiceAmt, ReverseCharge, SelectedCustomer, EditFlag, setEditFlag, InvoiceTypeObj,
            handleViewInvoice, DoInvoiceCalculation, InvoiceObj, setInvoiceObj, handleInvoiceDownload,
            ApproveInvoice, RejectInvoice, DeleteInvoice, GetAllInvoices, RejectObj, setRejectObj,
            Allinvoicepagination, setAllinvoicepagination, MailCheckMark, setMailCheckMark, AllCheckMark, setAllCheckMark, setInvoiceTypeObj,
            SearchContractNumberCMS, ContractCustomer, setContractCustomer, GstFlag, setGstFlag, regFlag, setRegFlag,
            GetContractCustomer, SalesAmountRegUnReg, setsalesAmountRegUnReg, RaiseCreditNote, Invoicecmsnomcms, handlenoncmssearchclick,
            AllinvoiceTypehandlePageChange, RaiseCreditNote, Getnoncmscustomerid, EditViewFlag, setEditViewFlag, setnoncmssearch, noncmssearch,
            MarginAmt, setMarginAmt, formatDate2, Getnoncmscustomerdata, ItemRegFlag, setItemRegFlag, ViseFlag, SetViseFlag,
            ShipIdentifier, setShipIdentifier, BillIdentifier, setBillIdentifier, InvoiceTypesRecent,
            // selectedShipAdd, setselectedShipAdd, selectedBillAdd, setselectedBillAdd,EditViewFlag, setEditViewFlag

            // user Access
            isAdmin, invoicingFlag, proformaFlag, moduleAccess, invoicing, invoiceAccess, proforma, proformaAccess,

            // Diwakar All invoice Ends

            // Pending Invoice props Starts 
            pendinginvoicedata, pendinginvoicepageNumbers, pendinginvoicePaginations, createcustomernoncms, handlechangebilladdress, billadressboxedit, handlesavenoncmsedit, Addaddressship,
            pendinginvoiceresponse, setpendingcreatedropdownvalue, CMSCustomerPaginationNumbers, setNoncmsbilladdress, Noncmsbilladdress, non_cms_Edit_show,
            pendinginvoicehandlePageChange, setpendinginvoiceselect, pendinginvoiceselect, noncmsbilladdress, Noncmsshipaddress, billaddressdelete,
            setpendinginvoicesearch, pendingcreatesuppliwrdata, handleCMSCustomerPaginationNumber, shipadressboxedit, Editboxaddressship,
            setpendinginvoiceshow, pendinginvoiceshow, serpendingcmserr, pendingcmserr, Noncmsshipaddress, Editboxaddressbill, seteditboxaddressbill,
            CMSCustomerDataObj, Pendingcustomerrescount, CMSCustomerPagination, NoncmsCreatedata, Noncmsshipaddresssave, handlechangeshipaddress, Editnoncmstable,
            setCMSCustomerSearch, CMSCustomerSearch, GetCMSCustomerDataObj, NON_CMSCustomerPaginationNumbers, CreateViewCustomerDataCount, handleNON_CMSCustomerPaginationNumber,
            paginationnoncmscustom, nonCMS, setnonCMS, CMS, setCMS, createnoncmsinput, create_customer_pending_approvel_cms, Addaddressbill, Customernoncmsdelete,
            IsProfoma, setIsProfoma, billaddressbox, shipadressboxdelete, handleitemsaveinputone, handleEditInserttwo, pendingcreditresponse, SetCMSCustomerPagination, setnoncmsinvoicedata, noncmsinvoicedata,
            cmspagenumbers, noncmsinvoicepagination, handlecmspagechange, GetLoginDetails, editidentifier, setpaginationnoncmcscustom,
            cmsNumber, setcmsNumber,
            // Pending Invoice props Ends 

            // indu Invoice Email List props starts
            handleClick, handleAscendingClick, AllInvoiceErr, handleSendmail, handlsingleemail, SubmitInvoice, handleClickInvoice,

            // indu Invoice Email List props Ends


            //dharshan credit invoice approved props starts 

            approvalcreditshow, setapprovalcreditshow, getApprovalcreditViewData, handleapprovalcreditviewdata,
            approvalcreditinvoicerecord, setapprovalcreditinvoicerecord, approvalcreditresponse, setapprovalcreditresponse,
            setapprovalcreditsearch, approvalcreditsearch, setapprovalCreditPagination, approvalcreditPagination, setapprovalcreditviewdata,
            approvalcreditviewdata, setapprovalcreditresponsedata, approvalcreditresponse, setapprovalcreditdata, approvalcreditdata,
            setCheckMark, CheckMark, handleapprovalsearch, approvalcreditpagenumbers, approvalcredithandlePageChange,
            approvalcreditinvoicedata, approvalcreditcontractdata, approvalcreditcalculatedata, approvalcreditsalesamnt, approvalcreditcustomercode, approvalcredititem,
            approvalcreditgstflag, approvalcreditsuppcode, approvalcreditinvoiceamnt, approvalcreditsuppliertype, approvalcreditinvoicetype,
            approvalcreditcalculatetype, handleSendmailapprovalcredit, handlemailCheckMark,

            //dharshan credit invoice approved props ends
            InvalidRouteHandler, pagenotfound
        }}>
            {children}
        </E_invoice_context.Provider>
    )
}

export { ContextProvider, E_invoice_context };